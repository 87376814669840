import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Cookies from "js-cookie";
import { Button, Badge, Paper, Grid, TextField, withStyles, MenuItem, Tooltip, IconButton, Switch, FormControlLabel, LinearProgress } from "@material-ui/core";
import WarningIcon from '@material-ui/icons/Warning';
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import RefreshIcon from "@material-ui/icons/Refresh";
import ErrorIcon from '@material-ui/icons/Error';
import SwapHorizontalCircleIcon from "@material-ui/icons/SwapHorizontalCircle";
import SyncIcon from '@material-ui/icons/Sync';
import styles from "../styles";
import ChartBase from "./ChartBase.js";
import ChartEFSignature from "./ChartEFSignature";
import ChartEFTime from "./ChartEFTime";
import ChartDSignature from "./ChartDSignature";
import ChartDTime from "./ChartDTime";
import ExportData from "./ExportData";
import Plotly from "plotly.js-basic-dist-min";
import axios from "axios";
import moment from 'moment'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogActions from "@material-ui/core/DialogActions";
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


class Charts extends React.Component {
  constructor(props) {
    super();

    var diagramDataIncludeFrom = new Date();
    diagramDataIncludeFrom.setUTCHours(0, 0, 0, 0);
    var diagramDataIncludeTo = new Date();
    diagramDataIncludeTo.setUTCHours(0, 0, 0, 0);
    diagramDataIncludeFrom.setUTCFullYear(diagramDataIncludeFrom.getUTCFullYear() - 3);

    var days = new Date();
    var year = days.getFullYear();
    var month = days.getMonth();
    var day = days.getDate();
    var validateDataFrom = new Date(year, month, day - 450);
    validateDataFrom.setUTCHours(0, 0, 0, 0);
    var validateDataTo = new Date();
    validateDataTo.setUTCHours(0, 0, 0, 0);
    // Change d3 locale format function
    // Used for matching y axes which are currently disabled.
    // Uncomment if this feature is to be used again.
    var org_locale = Plotly.d3.locale;
    Plotly.d3.locale = (locale) => {
      var result = org_locale(locale);
      var org_number_format = result.numberFormat;
      result.numberFormat = (format) => {
        const splits = format.split("_");
        if (splits[0] === "scale") {
          const k = parseFloat(splits[1]);
          const oldFormat = (splits.length > 2) ? splits[2] : "";
          return (x) => {
            return org_number_format(oldFormat)(x*k);
          }
        }
        else {return org_number_format(splits[0])}
      }
      return result;
    }

    this.state = {
      diagramDataIncludeFrom,
      diagramDataIncludeTo,
      markedPeriodEnd: diagramDataIncludeTo,
      markedPeriodStart: null,
      markedPeriodLength: 0,
      separateCalculated: (this.readCookie("k2_separate_calculated", ["true", "false"]) === "true"),
      freeze: (this.readCookie("k2_period_freeze", ["true", "false"]) === "true"),
      chartHoverLabel: (this.readCookie("k2_chartHoverLabel", ["true", "false"]) === "true"),
      themeName: this.readCookie("k2_themename", ["default", "classic"]),
      chartWidths: parseInt(this.readCookie("k2_chartWidths", ["4", "5", "6", "7", "8"], "6")),
      chartHeight: parseInt(this.readCookie("k2_chartHeight", [...Array(19).keys()].map(x => ""+(x*50+100)), "400")),
      chartLinking: this.readCookie("k2_chartLinking", ["enabled", "disabled"]),
      chartSymbol: this.readCookie("k2_chartSymbol", ["circle", "square", "diamond", "cross", "x", "triangle-up"]),
      chartSymbolSize: parseInt(this.readCookie("k2_chartSymbolSize", [...Array(20).keys()].map(x => ""+(x+1)), "4")),
      usekW: false,
      usem3ph: false,
      // usekW: (this.readCookie("k2_usekW", ["false", "true"]) === "true"),
      // usem3ph: (this.readCookie("k2_usem3ph", ["false", "true"]) === "true"),
      showRefPeriodInDiagram: false,
      energyReadings: [],
      flowReadings: [],
      energyReadingsAlarms: [],
      flowReadingsAlarms: [],
      tempReadings: [],
      waitingForData: false,
      districtCoolingID: (process.env.REACT_APP_DISTRICT_COOLING_ID || 
        "FK;Fjärrkyla;District Cooling").split(';'),
      labelPlotsErg: {},
      labelPlotsVol: {},
      selectedPlotsErg: {},
      selectedPlotsVol: {},
      showBadData: (this.readCookie("k2_show_bad_data", ["true", "false"]) === "true"),
      badDataDialogOpen: false,
      newLabelId:"",  // for testing only
      openValidateDialog:false,
      validateDataFrom,
      validateDataTo,
      showReValidatedInDiagram: false,
      showRevalidateButton: !(!!props.meteringpoint.reference_from && !!props.meteringpoint.reference_to),
      missingInfoEnergy: { missingDates: [], missingRatio: 0, latestValidated: ""},
      missingInfoFlow: { missingDates: [], missingRatio: 0, latestValidated: ""}
    };
  }

  periods = {
    0: {count: 0, period: "chart.no_marking"},
    1: {count: 1, period: "day"},
    7: {count: 1, period: "week"},
    14: {count: 2, period: "weeks"},
    30: {count: 1, period: "month"},
    90: {count: 3, period: "months"},
    180: {count: 6, period: "months"},
    365: {count: 1, period: "year"}
  };

  readCookie(cookieName, values, defaultValue=null) {
    const val = Cookies.get(cookieName);
    if (typeof val === undefined || values.indexOf(val) === -1) {
      if (defaultValue === null) {
        return values[0];
      } else {
        return defaultValue;
      }
    } else {
      return val;
    }
  }

  componentDidMount() {
    this.fetchDiagramData();
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.previewRefPeriod === null) && (this.props.previewRefPeriod !== null)) {
      this.fetchDiagramData(null, null, ...this.props.previewRefPeriod);
    } else if ((prevProps.previewRefPeriod !== null) && (this.props.previewRefPeriod === null)) {
      this.fetchDiagramData();
    } else if ((prevProps.previewRefPeriod !== null) && (this.props.previewRefPeriod !== null)) {
      if ( (prevProps.previewRefPeriod[0] !== this.props.previewRefPeriod[0]) ||
          (prevProps.previewRefPeriod[1] !== this.props.previewRefPeriod[1]) ) {
        this.fetchDiagramData(null, null, ...this.props.previewRefPeriod);
      }
    }

    if (prevProps.meteringPointLabels !== this.props.meteringPointLabels) {  
      try {
        var newLabelId = 0;
        if ("meteringPointLabels" in this.props) {
          newLabelId = this.props.meteringPointLabels.slice(-1).pop()["label_id"]
          this.handleCreateLabel(newLabelId)
        } else {
          console.log("error in loading the label id")          
        }
      } catch {
        console.log("error in loading the label id")
      }
    }

    if (prevState.showBadData != this.state.showBadData) {
      this.fetchDiagramData();
    }
  }

  generateShortUID = () => {
    // I generate the UID from two parts here 
    // to ensure the random number provide enough bits.
    var firstPart = (Math.random() * 46656) | 0;
    var secondPart = (Math.random() * 46656) | 0;
    firstPart = ("000" + firstPart.toString(36)).slice(-3);
    secondPart = ("000" + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
  }

  /**
   * Handles input to a datepicker
   *
   * @param {*} component The component that is being updated
   * @param {*} value The new value to be displayed in the component
   */
  handleDateInput = (component, value) => {
    var newDate = new Date(value);
    //Adjust time because of DatePicker component timezone inconsistency
    if (newDate.getUTCHours() > 12) {
      newDate.setUTCHours(24);
    } else {
      newDate.setUTCHours(0);
    }
    this.setState({
      [component]: newDate
    });
  };

  /**
   * Update the length of the red marked interval when the GUI is interacted with
   */
  handleMarkedPeriodLengthChange = event => {
    let markedPeriodLength = parseInt(event.target.value);
    let markedPeriodStart = null;
    let markedPeriodEnd = this.state.markedPeriodEnd;

    if (markedPeriodLength !== 0) {
      if (this.state.markedPeriodEnd) {
        markedPeriodEnd = new Date(this.state.markedPeriodEnd);
        markedPeriodStart = new Date(this.state.markedPeriodEnd);
      } else {
        markedPeriodEnd = new Date(this.state.diagramDataIncludeTo);
        markedPeriodStart = new Date(this.state.diagramDataIncludeTo);
      }
      markedPeriodStart.setUTCDate(markedPeriodStart.getUTCDate() - markedPeriodLength + 1);
    }

    this.setState({
      markedPeriodLength,
      markedPeriodStart,
      markedPeriodEnd,
    });
  };

  handleMarkedPeriodEndDateChange = value => {
    var newEndDate = new Date(value);
    newEndDate.setUTCHours(24, 0, 0, 0);
    const { markedPeriodLength } = this.state;
    if (markedPeriodLength === 0) {
      this.setState({
        markedPeriodEnd: newEndDate,
      });
    } else {
      var newStartDate = new Date(newEndDate);
      newStartDate.setUTCDate(newStartDate.getUTCDate() - markedPeriodLength + 1);
      this.setState({
        markedPeriodEnd: newEndDate,
        markedPeriodStart: newStartDate,
      });
    }
  };

  handleMarkedPeriodStep = direction => {
    var markedPeriodStart = new Date(this.state.markedPeriodStart);
    var markedPeriodEnd = new Date(this.state.markedPeriodEnd);
    const dShift = ((direction === "forward") ? 1 : -1) * this.state.markedPeriodLength;
    var today = new Date();
    today.setUTCHours(0,0,0,0);
    const markedPeriodLengthMS = this.state.markedPeriodLength*(24*60*60*1000);
    markedPeriodEnd.setUTCDate(markedPeriodEnd.getUTCDate() + dShift);
    if (markedPeriodEnd.getTime() > today.getTime()) {
      markedPeriodEnd.setTime(today.getTime());
    }
    markedPeriodStart.setTime(markedPeriodEnd.getTime() - markedPeriodLengthMS + 24*60*60*1000);

    if (!this.state.freeze) {
      var newDiagramDataIncludeTo = new Date(this.state.diagramDataIncludeTo);
      var newDiagramDataIncludeFrom = new Date(this.state.diagramDataIncludeFrom);
      const viewedPeriodLength = newDiagramDataIncludeTo - newDiagramDataIncludeFrom;
      newDiagramDataIncludeTo.setUTCDate(newDiagramDataIncludeTo.getUTCDate() + dShift);
      if (newDiagramDataIncludeTo.getTime() > today.getTime()) {
        newDiagramDataIncludeTo.setTime(today.getTime());
      }
      newDiagramDataIncludeFrom.setTime(newDiagramDataIncludeTo.getTime() - viewedPeriodLength);
      this.fetchDiagramData(newDiagramDataIncludeFrom, newDiagramDataIncludeTo);
      this.setState({
        diagramDataIncludeFrom: newDiagramDataIncludeFrom,
        diagramDataIncludeTo: newDiagramDataIncludeTo
      });
    }
    this.setState({
      markedPeriodStart,
      markedPeriodEnd,
    });
  };

  handleOpenBadDataDialog = () => {
    this.setState({badDataDialogOpen: true});
  }

  handleInvalidDataClose = () => {
    this.setState({badDataDialogOpen: false});
  }

  handleCreateInvalidData = () => {
    this.createInvalidData(false)
  }

  handleCreateInvalidDataUndo = () => {
    this.createInvalidData(true)
  }

  createInvalidData = (undo) => {
    this.setState({badDataDialogOpen: false});

    let labelPostData = this.getLabelPostData()
    if (labelPostData.length>0) { 
      let labelPlotDataErg = this.createLabelPlotData(this.state.selectedPlotsErg, "invalid-data", "energy");
      let labelPlotDataVol = this.createLabelPlotData(this.state.selectedPlotsVol, "invalid-data", "flow");

      let post_data = {
        "readings": labelPostData,
        "metering_point_id": String(this.props.meteringpoint.metering_point_id)
      }
      let addUndoHeaderField = {"undo": undo}
      this.postLabels(post_data, labelPlotDataErg, labelPlotDataVol, this.props.context.config.meterReadingsAPI+"/invalid_data", "put", addUndoHeaderField);
    }

  }

  handleCreateLabel = (newLabelId) => {
    let labelPostData = this.getLabelPostData()
    if (labelPostData.length>0) {
      let labelPlotDataErg = this.createLabelPlotData(this.state.selectedPlotsErg, newLabelId, "energy");
      let labelPlotDataVol = this.createLabelPlotData(this.state.selectedPlotsVol, newLabelId, "flow");

      let post_data = {
        "label_id": newLabelId,
        "readings": labelPostData,
        "metering_point_id": String(this.props.meteringpoint.metering_point_id)
      }
      this.postLabels(post_data, labelPlotDataErg, labelPlotDataVol, this.props.context.config.meterReadingsAPI+"/labels", "post");
    }
  }

  createLabelPlotData = (selectedPlots, labelID, metering_type) => {
    let labelPlotData = {};
    for (let key in selectedPlots) {
      let group = selectedPlots[key];
      let newPlot = {...group};
      newPlot.label_id = labelID ;
      newPlot.name = "group "+ newPlot.group_id + " label "+ newPlot.label_id;
      let unique_key = newPlot.label_id + ":" + newPlot.group_id + ":" + metering_type
      labelPlotData[unique_key] = newPlot;
    }
    return labelPlotData;
  }

  getLabelPostData = () => {
    let labelPostDataErg = this.createLabelPostData(this.state.selectedPlotsErg, "energy");
    let labelPostDataVol = this.createLabelPostData(this.state.selectedPlotsVol, "flow");
    return labelPostDataErg.concat(labelPostDataVol);
  }

  createLabelPostData = (selectedPlots, metering_type) => {
    let postdata = [];
    for (var key in selectedPlots) {
      let group = selectedPlots[key];
      group.timestamp.forEach((stamp) => {
        let item = {
          "property": metering_type,
          "timestamp": stamp,
        }
        postdata.push(item);
      });
    }
    return postdata;
  }

  postLabels = (postData, labelPlotDataErg, labelPlotDataVol, url, method, addHeaderFields = {}) => {
    let jsonData = JSON.stringify(postData);
    var thiz = this;
    this.props.context.keycloak
      .updateToken(5)
      .success(function() {
        var config = {
          method: method,
          url: url, 
          headers: {
            Authorization: "Bearer " + thiz.props.context.keycloak.token
          },
          data: postData,
          responseType: "json",
          params: {}
        };
        for (var key in addHeaderFields) {
          config["params"][key] = addHeaderFields[key];
        }

        axios(config)
          .then((response) => {
            let emptySelection = {};
            thiz.handleNewSelectionErg(emptySelection);
            thiz.handleNewSelectionVol(emptySelection);

            // Explanation: Insert the newly created plotdata into energy and volume charts.
            // This is done for speed. Data is not loaded from server but instead taken here from memory.
            let appendedPlotsErg = {...thiz.state.labelPlotsErg};
            for (let key in labelPlotDataErg) {
              appendedPlotsErg[key] = labelPlotDataErg[key];
            }
            thiz.setState({labelPlotsErg: appendedPlotsErg});

            let appendedPlotsVol = {...thiz.state.labelPlotsVol};
            for (let key in labelPlotDataVol) {
              appendedPlotsVol[key] = labelPlotDataVol[key];
            }
            thiz.setState({ labelPlotsVol: appendedPlotsVol});
          })
          .catch(error => {
            console.log("postLabels failed "+ error);
          });
      })
      .error(function() {
        thiz.props.context.keycloak.login();
      });
  };

  handleNewSelectionErg = selectedPlotsErg => {
    this.setState({selectedPlotsErg: selectedPlotsErg});
    this.props.handleDataSelection(selectedPlotsErg, this.state.selectedPlotsVol);
  }

  handleNewSelectionVol = selectedPlotsVol => {
    this.setState({selectedPlotsVol: selectedPlotsVol});
    this.props.handleDataSelection(this.state.selectedPlotsErg, selectedPlotsVol);
  }

  splitAlarmsBasedOnProperty = data => {
    let energyReadingsAlarms = [];
    let flowReadingsAlarms = [];
    data.forEach(element => {
      if (element.property === "energy") {
        energyReadingsAlarms.push(element);
      }
      if (element.property === "flow") {
        flowReadingsAlarms.push(element);
      }
    });
    return [energyReadingsAlarms, flowReadingsAlarms];
  };

  fetchDiagramData = (diagramDataIncludeFrom=null, diagramDataIncludeTo=null, refIncludeFrom=null, refIncludeTo=null) => {
    var thiz = this;
    if (diagramDataIncludeFrom === null) {
      diagramDataIncludeFrom = thiz.state.diagramDataIncludeFrom;
    }
    if (diagramDataIncludeTo === null) {
      diagramDataIncludeTo = thiz.state.diagramDataIncludeTo;
    }
    var todaysDate = new Date();
    var startDate = new Date();
    var savedAlarmsPeriod = parseInt(Cookies.get("k2_alarms_period"))
    // var period = parseInt(thiz.props.alarmsPeriod); 
    var period = savedAlarmsPeriod;
    if (period >= 0) {
      startDate.setUTCDate(todaysDate.getUTCDate() - period);
    }

    function createPlotData_Old(readings) {
      // let labelPlotData = createLabelsPlotData(readings);
      // let invalidData_PlotData = createInvalidDataPlotData(readings);
      // return Object.assign({}, labelPlotData, invalidData_PlotData);
      return {}; //TODO: fix this
    }

    function createPlotData(readings) {
      return ChartBase.populateLabelsExtremeData(thiz.props.intl, readings)
    }

    function getMissingInfo(from, to, meterReadings) {
      if (!meterReadings) {
        return {
          missingDates: [],
          notValidatedDates: [],  
          missingRatio: 0,
          notValidatedRatio: 0,
          latestValidated: ""
        }
      }
      const dateMap = meterReadings.reduce((memo, m) => ({...memo, [m.timestamp]: { validated: m.validated}}), {});
      let missingDates = [];
      let notValidatedDates = [];
      let rangeCount = 0;
      let notMissingCount = 0;
      let current = moment(from);
      let latestValidated = "";
      while (current.isBefore(moment(to))) {
        const dateStr = current.format("YYYY-MM-DD") + "T00:00:00Z";
        if (!dateMap[dateStr]) {
          missingDates.push(dateStr);
        } else {
          if (dateMap[dateStr].validated) {
            latestValidated = dateStr
          } else {
            notValidatedDates.push(dateStr)
          }
          notMissingCount++;
        }       
        current.add(1, "days")
        rangeCount++;
      }
      const missingRatio = (missingDates.length / rangeCount * 100).toFixed(1);
      const notValidatedRatio = (notValidatedDates.length / notMissingCount * 100).toFixed(1)
      return {
        missingDates,
        notValidatedDates,  
        missingRatio,
        notValidatedRatio,
        latestValidated
      }
    }

    function getRemoteData() {
      var haveEnergy = false, haveFlow = false;
      const hasRefPeriod = !!thiz.props.meteringpoint.reference_from && !!thiz.props.meteringpoint.reference_to;
      const url = thiz.props.context.config.regressionAPI + (hasRefPeriod ? "/period" : "/period_without_calc");
      const headers = { Authorization: "Bearer " + thiz.props.context.keycloak.token };
      const energyParams = {
        metering_point_id: thiz.props.meteringpoint.metering_point_id,
        include_from: diagramDataIncludeFrom.toISOString(),
        include_to: diagramDataIncludeTo.toISOString(),
        property: "energy",
        _format: "new1",
        exclude_extremes: !thiz.state.showBadData
      };

      if (refIncludeFrom !== null && refIncludeTo !== null) {
        energyParams.ref_from = refIncludeFrom.toISOString()
        energyParams.ref_to = refIncludeTo.toISOString()
      }
      const flowParams = Object.assign({}, energyParams, {property: "flow"});
      const tempsURL = thiz.props.context.config.meterReadingsAPI + "/temperatures";
      const tempsParams = Object.assign({limit: 99999999999}, energyParams)
      delete tempsParams["property"];
      const alarmsURL = thiz.props.context.config.meterReadingsAPI + "/validated";
      const alarmsParams = {
        metering_point_id: thiz.props.meteringpoint.metering_point_id,
        include_from: startDate.toISOString(),
        include_to: todaysDate.toISOString(),
        fields: "calculated,consumption",
        interval: "daily",
        validated: "true",
        limit: "99999999"
      };

      // Fetch data with XHTMLHttpRequest. Is it faster than Axios?
      var energyXHR = new XMLHttpRequest();
      energyXHR.responseType = "json";
      let urlEnergy = url+"?"+Object.keys(energyParams).map(key => key + "=" + energyParams[key]).join("&")
      energyXHR.open('GET', urlEnergy)
      energyXHR.setRequestHeader("Authorization", headers["Authorization"]);
      // energyXHR.setRequestHeader("Access-Control-Max-Age", 600)
      energyXHR.onload = function() {
        //let energyReadings = {"reg": [], "ref": []}, flowReadings = {"reg": [], "ref": []};
        let energyReadings = {};
        let flowReadings = {};
        if (haveFlow) { //Update energy and flow simultaneously
          if (this.status === 200 && energyXHR.response != null) {
            energyReadings = energyXHR.response['energy'];
            flowReadings = flowXHR.response['flow'];
            // energyReadings = {"reg": energyXHR.response.view_groups, "ref": energyXHR.response.reference_groups};
            // flowReadings = {"reg": flowXHR.response.view_groups, "ref": flowXHR.response.reference_groups};
          }
          const missingInfoEnergy = getMissingInfo(diagramDataIncludeFrom, diagramDataIncludeTo, energyReadings)
          const missingInfoFlow = getMissingInfo(diagramDataIncludeFrom, diagramDataIncludeTo, flowReadings)
          thiz.setState({energyReadings, flowReadings, missingInfoEnergy, missingInfoFlow, nrDataSetsWaiting: thiz.state.nrDataSetsWaiting-2})

          let plotdata = createPlotData(energyReadings)
          thiz.setState({labelPlotsErg: plotdata});

          plotdata = createPlotData(flowReadings)
          thiz.setState({labelPlotsVol: plotdata});
        } else {//Update only energy
          if (this.status === 200 && energyXHR.response != null) {
            // energyReadings = {"reg": energyXHR.response.view_groups, "ref": energyXHR.response.reference_groups};
            energyReadings = energyXHR.response['energy'];
          }   
          const missingInfoEnergy = getMissingInfo(diagramDataIncludeFrom, diagramDataIncludeTo, energyReadings)
          thiz.setState({energyReadings, missingInfoEnergy, nrDataSetsWaiting: thiz.state.nrDataSetsWaiting-1})

          let plotdata = createPlotData(energyReadings)
          thiz.setState({labelPlotsErg: plotdata});
        }
        haveEnergy = true;
      }
      energyXHR.send();

      var flowXHR = new XMLHttpRequest();
      flowXHR.responseType = 'json';
      flowXHR.open('GET', url+"?"+Object.keys(flowParams).map(key => key + "=" + flowParams[key]).join("&"))
      flowXHR.setRequestHeader("Authorization", headers["Authorization"]);
      // flowXHR.setRequestHeader("Access-Control-Max-Age", 600)
      flowXHR.onload = function() {
        // let flowReadings = {"reg": [], "ref": []};
        let flowReadings = {};
        if (haveEnergy) {//Update flow chart data
          if (this.status === 200 && flowXHR.response != null) {
            // flowReadings = {"reg": flowXHR.response.view_groups, "ref": flowXHR.response.reference_groups};
            flowReadings = flowXHR.response['flow'];
          }
          const missingInfoFlow = getMissingInfo(diagramDataIncludeFrom, diagramDataIncludeTo, flowReadings)
          
          thiz.setState({flowReadings, missingInfoFlow, nrDataSetsWaiting: thiz.state.nrDataSetsWaiting-1})
          let plotdata = createPlotData(flowReadings)
          thiz.setState({labelPlotsVol: plotdata});
        }
        haveFlow = true;
      }
      flowXHR.send();

      var tempsXHR = new XMLHttpRequest();
      tempsXHR.responseType = 'json';
      tempsXHR.open('GET', tempsURL+"?"+Object.keys(tempsParams).map(key => key + "=" + tempsParams[key]).join("&"))
      tempsXHR.setRequestHeader("Authorization", headers["Authorization"]);
      // tempsXHR.setRequestHeader("Access-Control-Max-Age", 600)
      tempsXHR.onload = function() {
        if (this.status === 200) {
          thiz.setState({tempReadings: tempsXHR.response, nrDataSetsWaiting: thiz.state.nrDataSetsWaiting-1})
        } else {
          thiz.setState({tempReadings: [], nrDataSetsWaiting: thiz.state.nrDataSetsWaiting-1})
        }
      }
      tempsXHR.send();

      if (period >= 0) {
        var alarmsXHR = new XMLHttpRequest();
        alarmsXHR.responseType = 'json';
        alarmsXHR.open('GET', alarmsURL+"?"+Object.keys(alarmsParams).map(key => key + "=" + alarmsParams[key]).join("&"))
        alarmsXHR.setRequestHeader("Authorization", headers["Authorization"]);
        // alarmsXHR.setRequestHeader("Access-Control-Max-Age", 600)
        alarmsXHR.onload = function() {
          if (this.status === 200) {
            const [energyReadingsAlarms, flowReadingsAlarms] = 
                thiz.splitAlarmsBasedOnProperty(alarmsXHR.response);
            thiz.setState({energyReadingsAlarms, flowReadingsAlarms, 
                nrDataSetsWaiting: thiz.state.nrDataSetsWaiting-1})
          } else {
            thiz.setState({energyReadingsAlarms: [], flowReadingsAlarms: [],
                nrDataSetsWaiting: thiz.state.nrDataSetsWaiting-1})
          }
        }
        alarmsXHR.send();
      }

      // // With Axios. Seems slower than XHR, but it will be left here for now.
      // axios.get(url, { headers, params: energyParams }).then(
      //   (response) => {
      //     const energyReadings = {"reg": response.data.view_groups, "ref": response.data.reference_groups};
      //     thiz.setState({energyReadings, nrDataSetsWaiting: thiz.state.nrDataSetsWaiting-1})
      //   }
      // ).catch(error => {
      //   console.log(error);
      //   thiz.setState({nrDataSetsWaiting: thiz.state.nrDataSetsWaiting-1})
      // });
      // axios.get(url, { headers, params: flowParams }).then(
      //   (response) => {
      //     const flowReadings = {"reg": response.data.view_groups, "ref": response.data.reference_groups};
      //     thiz.setState({flowReadings, nrDataSetsWaiting: thiz.state.nrDataSetsWaiting-1})
      //   }
      // ).catch(error => {
      //   console.log(error);
      //   thiz.setState({nrDataSetsWaiting: thiz.state.nrDataSetsWaiting-1})
      // });
      // axios.get(tempsURL, { headers, params: tempsParams }).then(
      //   (response) => {
      //     thiz.setState({tempReadings: response.data, nrDataSetsWaiting: thiz.state.nrDataSetsWaiting-1})
      //   }
      // ).catch(error => {
      //   console.log(error);
      //   thiz.setState({nrDataSetsWaiting: thiz.state.nrDataSetsWaiting-1})
      // });
      // if (period >= 0) {
      //   axios.get(alarmsURL, { headers, params: alarmsParams }).then(
      //     (response) => {
      //       const [energyReadingsAlarms, flowReadingsAlarms] =
      //           thiz.splitAlarmsBasedOnProperty(response.data);
      //       thiz.setState({
      //         energyReadingsAlarms,
      //         flowReadingsAlarms,
      //         nrDataSetsWaiting: thiz.state.nrDataSetsWaiting-1
      //       })
      //     }
      //   );
      // }
    }

    if (!thiz.props.context.keycloak.isTokenExpired(10)) {
      getRemoteData();
    } else {
      thiz.props.context.keycloak.updateToken(10).success(getRemoteData)
      .error(function() {
        thiz.props.context.keycloak.login();
        thiz.setState({ nrDataSetsWaiting: 0 });
      });
    };

    thiz.setState({
      nrDataSetsWaiting: (period >= 0) ? 4 : 3,
    });
  };

  toggleFreeze = () => {
    var newFreezeValue = !this.state.freeze;
    Cookies.set("k2_period_freeze", newFreezeValue, {expires: new Date(Date.now()+1000000000000), sameSite: "lax"});
    this.setState({ freeze: newFreezeValue });

  };

  toggleChartHoverLabel = () => {
    var newChartHoverLabelValue = !this.state.chartHoverLabel;
    Cookies.set("k2_chartHoverLabel", newChartHoverLabelValue, {expires: new Date(Date.now()+1000000000000), sameSite: "lax"});
    this.setState({ chartHoverLabel: newChartHoverLabelValue });
  };

  toggleSeparateCalculated = () => {
    var newSeparateCalculated = !this.state.separateCalculated;
    Cookies.set("k2_separate_calculated", newSeparateCalculated,
      {expires: new Date(Date.now()+1000000000000), sameSite: "lax"});
    this.setState({ separateCalculated: newSeparateCalculated });
  };

  toggleBadData = () => {
    var newShowBadDataValue = !this.state.showBadData;
    Cookies.set("k2_show_bad_data", newShowBadDataValue, {expires: new Date(Date.now()+1000000000000), sameSite: "lax"});
    this.setState({ showBadData: newShowBadDataValue });
  };

  toggleEnergyChartUnit = () => {
    var newUsekW = !this.state.usekW;
    Cookies.set("k2_usekW", newUsekW, {expires: new Date(Date.now()+1000000000000), sameSite: "lax"});
    this.setState({ usekW: newUsekW });
  };

  toggleFlowChartUnit = () => {
    var newUsem3ph = !this.state.usem3ph;
    Cookies.set("k2_usem3ph", newUsem3ph, {expires: new Date(Date.now()+1000000000000), sameSite: "lax"});
    this.setState({ usem3ph: newUsem3ph });
  };

  swapViewDate = () => {
    if (this.state.showRefPeriodInDiagram) {
      this.fetchDiagramData();
    } else {
      const from = new Date(this.props.meteringpoint.reference_from);
      const to = new Date(this.props.meteringpoint.reference_to);
      this.fetchDiagramData(from, to);
    }
    this.setState({showRefPeriodInDiagram: !this.state.showRefPeriodInDiagram})
  };

  swapToValidatedDate = () => {
    if (this.state.showReValidatedInDiagram) {
      this.fetchDiagramData();
    } else {
      const from = new Date(this.state.validateDataFrom.toISOString());
      const to = new Date(this.state.validateDataTo.toISOString());
      this.fetchDiagramData(from, to);
    }
    this.setState({showReValidatedInDiagram: !this.state.showReValidatedInDiagram})
  };


  /**
   * Get the color palette based on the theme name.
   * group, ref and reg should be the same color, but with a difference in grade/intensity,
   * group - dark, reg - medium, ref - light
   *
   * @param {*} theme
   */
  getColorPattern = theme => {
    let colorPattern = {
      group1: "rgb(50,50,242)",
      ref1: "rgb(10,233,241)",
      reg1: "rgb(87,157,242)",
      group2: "rgb(18,157,0)",
      ref2: "rgb(30,243,25)",
      reg2: "rgb(164,255,162)",
      group3: "rgb(255,197,138)",
      ref3: "rgb(153,51,0)",
      reg3: "rgb(255,153,102)",
      group4: "rgb()",
      ref4: "rgb()",
      reg4: "rgb()",
      temperature: "rgb(243,100,16)",
      bgcolor: "rgb(255,255,255)",
      papercolor: "rgb(255,255,255)",
      marked: "rgb(255,230,0)",
      marked_2: "rgb(255,140,10)",
      temp_flow: "rgb(200,0,0)",
      temp_return: "rgb(0,0,255)",
      temp_delta: "rgb(56,184,53)",
      alarms: "rgb(163,73,164)", 
      missing: "rgb(10, 10, 10)"
    };

    switch(theme) {
      case "classic":
        colorPattern["bgcolor"] = "rgb(204,204,204)";
        colorPattern["papercolor"] = "rgb(254,255,255)";
        colorPattern["marked"] = "rgb(237,28,36)"
        colorPattern["marked_2"] = "rgb(237,28,36)"
        colorPattern["temp_flow"] = "rgb(70,0,0)"
        break;
      case "default":
      default:
        //Change nothing
        break;
    }
    return colorPattern;


  };


  binarySearch(array, value) {
    if (!array) {return -1;}
    if (!value && value !== 0) {return -2;}
    if (array.length === 0) {return -3;}
    if (typeof(array[0]) !== typeof(value)) {return -4;}
    var low = 0, high = array.length-1, mid;
    while (low <= high) {
      mid = Math.floor((high+low)/2);
      if (array[mid] < value) {
        low = mid+1;
      } else if (array[mid] > value) {
        high = mid-1;
      } else {
        return mid;
      }
    }
    return -5;
  }

  syncHover(src, p, hover) {
    const chartIDs = [
      "energy_signature",
      "energy_time",
      "flow_signature",
      "flow_time",
      "chart.delta_T_diagram_signature",
      "chart.delta_T_diagram_time"
    ];
    if (!(hover && p.points)) {
      for (let i=0; i < chartIDs.length; ++i) {
        Plotly.Fx.hover(document.getElementById(chartIDs[i]), []);
      }
      return;
    }

    var dateKey, tempKey;
    var chartType = src.split("_")
    chartType = chartType[chartType.length-1];
    if (chartType === "time") {
      dateKey = p.points[0]['data']['x'][p.points[0].pointNumber];
      if (p.points[0]['data']['name'] === "Temp") {
        tempKey = p.points[0]['data']['y'][p.points[0].pointNumber];
      }
    } else if (chartType === "signature") {
      dateKey = p.points[0]['data']['text'][p.points[0].pointNumber];
      if (p.points[0]['data']['name'] && p.points[0]['data']['name'][0] === "G") {
        tempKey = p.points[0]['data']['x'][p.points[0].pointNumber];
      }
    }
    const srci = chartIDs.indexOf(src);
    var chartDivsHover;
    switch (this.state.chartLinking) {
      // case "vertical": //Not currently in use
      //   chartDivsHover = [srci + 2 - 4*(Math.floor(srci/2))];
      //   break;
      // case "horizontal": //Not currently in use
      //   chartDivsHover = [srci + 1 - 2*(srci % 2)];
      //   break;
      // case "full": //Not currently in use. Same function as "enabled", though.
      case "enabled":
        chartDivsHover = [0,1,2,3,4,5].filter(i => (i !== srci));
        break;
      // case "none": //Not currently in use, but may be in the future
      case "disabled":
      default:
        chartDivsHover = [];
        break;
    }

    for (const chartID of chartDivsHover) {
      const div = document.getElementById(chartIDs[chartID]);
      if (hover === true && p.points) { //Hover, display data label
        var curves = [];
        for (let k = 0; k < div.data.length; ++k) {
          let pn3 = this.binarySearch(div.data[k]["text"], dateKey);
          if (pn3 > -1) {
            curves.push({ curveNumber: k, pointNumber: pn3 });
          } else {
            pn3 = this.binarySearch(div.data[k]["text"], tempKey);
            if (pn3 > -1) {
              curves.push({ curveNumber: k, pointNumber: pn3 });
            } 
          }
        }
        Plotly.Fx.hover(div, curves);
      } else {  //Unhover
        Plotly.Fx.hover(div, []);
      }
    }
  }

  syncZoom(src, layout) {
    if (layout['from_syncZoom']) {
      return; //This call was made recursively. Don't recurse again.
    }
    if (this.state.chartLinking === "disabled") {
      return; //Charts not linked. Don't proceed.
    }

    const chartIDs = [
      "energy_signature",
      "energy_time",
      "flow_signature",
      "flow_time",
      "chart.delta_T_diagram_signature",
      "chart.delta_T_diagram_time"
    ];
    var chartType = src.split("_");
    chartType = chartType[chartType.length-1];
    const srci = chartIDs.indexOf(src);
    const yCi = srci + 1 - 2*(srci % 2);

    if (chartType === "signature") {
      for (let i = 0; i <= 4; i+=2) {
        if (i !== srci) {
          const div = document.getElementById(chartIDs[i]);
          const newLayout = {
            "xaxis.range[0]": layout["xaxis.range[0]"],
            "xaxis.range[1]": layout["xaxis.range[1]"],
            "xaxis.autorange": layout["xaxis.autorange"],
            "autosize": layout["autosize"],
            "from_syncZoom": true,
          }
          Plotly.relayout(div, newLayout);
        }
      }
      for (let i = 1; i <= 5; i+=2) {
        const div = document.getElementById(chartIDs[i]);
        const newLayout = {
          "autosize": layout["autosize"],
          "from_syncZoom": true,
        }
        if (i === yCi) {
          newLayout["yaxis.range[0]"] = layout["yaxis.range[0]"];
          newLayout["yaxis.range[1]"] = layout["yaxis.range[1]"];
          newLayout["yaxis.autorange"] = layout["yaxis.autorange"];
        }
        if (i !== 5) {
          newLayout["yaxis2.range[0]"] = layout["xaxis.range[0]"];
          newLayout["yaxis2.range[1]"] = layout["xaxis.range[1]"];
          newLayout["yaxis2.autorange"] = layout["xaxis.autorange"];
        }
        Plotly.relayout(div, newLayout);
      }
    } else if (chartType === "time") {
      for (let i = 0; i <= 4; i+=2) {
        const div = document.getElementById(chartIDs[i]);
        const newLayout = {
          "autosize": layout["autosize"],
          "from_syncZoom": true,
        }
        if (i === yCi) {
        	newLayout["yaxis.range[0]"] = layout["yaxis.range[0]"];
        	newLayout["yaxis.range[1]"] = layout["yaxis.range[1]"];
          newLayout["yaxis.autorange"] = layout["yaxis.autorange"];
        }
        if (srci !== 5) {
          newLayout["xaxis.range[0]"] = layout["yaxis2.range[0]"];
          newLayout["xaxis.range[1]"] = layout["yaxis2.range[1]"];
          newLayout["xaxis.autorange"] = layout["yaxis2.autorange"];
        }
        Plotly.relayout(div, newLayout);
      }
      for (let i = 1; i <= 5; i+=2) {
        if (i !== srci) {
          const div = document.getElementById(chartIDs[i]);
          const newLayout = {
            "xaxis.range[0]": layout["xaxis.range[0]"],
            "xaxis.range[1]": layout["xaxis.range[1]"],
            "xaxis.autorange": layout["xaxis.autorange"],
            "autosize": layout["autosize"],
            "from_syncZoom": true,
          }
          if (i !== 5 && srci !== 5) {
            newLayout["yaxis2.range[0]"] = layout["yaxis2.range[0]"];
            newLayout["yaxis2.range[1]"] = layout["yaxis2.range[1]"];
            newLayout["yaxis2.autorange"] = layout["yaxis2.autorange"];
          }
          Plotly.relayout(div, newLayout);
        }
      }
    }
  }

  revalidateData = () => {
    var thiz = this;
    const url = this.props.context.config.regressionAPI + "/validate_arnold";
    this.props.context.keycloak
    .updateToken(5)
    .success(function() {
      var config = {
        method: "put",
        params:{
          metering_point_id: thiz.props.meteringpoint.metering_point_id,
          include_from: thiz.state.validateDataFrom.toISOString(),
          include_to: thiz.state.validateDataTo.toISOString(),
          do_crossval: true,
          weather_station_id: thiz.props.meteringpoint.weather_station_id,
          weather_station_agency: thiz.props.meteringpoint.weather_station_agency,
        },
        url:url,
        headers: {
          Authorization: "Bearer " + thiz.props.context.keycloak.token
        },
        responseType: "json"
      };
      axios(config)
      .then((res) => {
        thiz.setState({openValidateDialog: false})
        thiz.swapToValidatedDate()
      })
      .catch(error => {
        console.log(error)
      });
    })
  }

  handleOpenValidateDialog = () => {
    this.setState({openValidateDialog: true})
  };

  handleCloseValidateDialog = () => {
    this.setState({openValidateDialog: false})
  };


  render() {
    const { classes } = this.props;
    const waitingForData = (this.state.nrDataSetsWaiting > 0);

    //Is it District cooling?
    var maxTempLimit = 150
    if (this.props.meteringpoint && this.props.meteringpoint.tags) {
      for (let tag of this.props.meteringpoint.tags) {
        if (tag.key === "Leveranstyp") {
          for (let val of tag.val) {
            if (this.state.districtCoolingID.includes(val)) {
              maxTempLimit = 25;
              break;
            }
          }
        }
      }
    }

    function createDiagramsDatePicker(langFormat, labelFMID, onChange, value) {
      return (
        <KeyboardDatePicker
          className={classes.DatePicker + " " + classes.MarginRight}
          allowKeyboardControl
          autoOk
          disableFuture
          invalidDateMessage={langFormat({
            id: "invalid_date_message"
          })}
          minDateMessage={langFormat({
            id: "min_date_message"
          })}
          maxDateMessage={langFormat({
            id: "max_date_message"
          })}
          label={langFormat({
            id: labelFMID
          })}
          InputLabelProps={{ shrink: true }}
          format="YYYY-MM-DD"
          onChange={onChange}
          value={value}
        />
      );
    }

    return (
      <Paper>

        {/* create Bad Data Dialog*/}
        <div>
          <Dialog
            open={this.state.badDataDialogOpen}
            onClose={this.handleOpenBadDataDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Mark selected points."}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.props.intl.formatMessage({ id: "metering_point.invalid_data_popup_title" })}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MuiDialogActions>
                <Button variant="contained" onClick={this.handleInvalidDataClose} color="primary" autoFocus>
                {this.props.intl.formatMessage({ id: "cancel" })}
                </Button>
              </MuiDialogActions>
              <MuiDialogActions>
                <Button variant="contained" onClick={this.handleCreateInvalidDataUndo} color="primary" autoFocus>
                {this.props.intl.formatMessage({ id: "metering_point.invalid_data_popup_valid" })}
                </Button>
              </MuiDialogActions>
              <MuiDialogActions>
                <Button variant="contained" onClick={this.handleCreateInvalidData} color="primary" autoFocus>
                {this.props.intl.formatMessage({ id: "metering_point.invalid_data_popup_invalid" })}
                </Button>
              </MuiDialogActions>

            </DialogActions>
          </Dialog>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={7}>

            <React.Fragment>
              <Tooltip
                title={this.props.intl.formatMessage({
                  id: "chart.edit_bad_data",
                })}
              >
                <IconButton onClick={this.handleOpenBadDataDialog}>
                  <Badge color="secondary">
                    <ErrorIcon />
                  </Badge>
                </IconButton>
              </Tooltip>

              <FormControlLabel
                style={{marginLeft:"-6px"}}
                label={
                  <div className={classes.SwitchLabel}>
                    {this.props.intl.formatMessage({id: "chart.show_bad_data_short"})}
                  </div>
                }
                labelPlacement="top"
                control={
                  <Tooltip
                    title={this.props.intl.formatMessage({
                      id: "chart.show_bad_data"
                    })}
                  >
                    <Switch
                      checked={this.state.showBadData}
                      onChange={this.toggleBadData}
                      color="primary"
                    />
                  </Tooltip>
                }
              />

              <TextField
                className={classes.TextField + " " + classes.MarginRight + " " + classes.MarginLeft}
                select
                label={this.props.intl.formatMessage({
                  id: "chart.marked_period_length"
                })}
                value={this.state.markedPeriodLength}
                onChange={this.handleMarkedPeriodLengthChange}
              >
                {Object.keys(this.periods).map(period => (
                  <MenuItem key={period} value={period}>
                    {period !== "0" ? this.periods[period].count : null} &nbsp;
                    <FormattedMessage id={this.periods[period].period} />
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className={classes.TextField + " " + classes.MarginRight}
                label={this.props.intl.formatMessage({
                  id: "chart.marked_period_start_date"
                })}
                value={this.state.markedPeriodStart ? this.state.markedPeriodStart.toLocaleDateString("sv-SE") : "-"}
              />
              <MuiPickersUtilsProvider utils={MomentUtils}>
                {createDiagramsDatePicker(
                  this.props.intl.formatMessage,
                  "chart.marked_period_end_date",
                  this.handleMarkedPeriodEndDateChange,
                  this.state.markedPeriodEnd ? this.state.markedPeriodEnd.toLocaleDateString("sv-SE") : "-"
                )}
              </MuiPickersUtilsProvider>
              <Tooltip
                title={this.props.intl.formatMessage({
                  id: "chart.marked_period_step_back"
                })}
              >
                <span>
                  <IconButton
                    onClick={() => this.handleMarkedPeriodStep("back")}
                    disabled={this.state.markedPeriodLength === 0 ? true : false}
                  >
                    <ArrowBackIosRoundedIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip
                title={this.props.intl.formatMessage({
                  id: "chart.marked_period_step_forward"
                })}
              >
                <span>
                  <IconButton
                    className={classes.ArrowIconButtonsRight}
                    onClick={() => this.handleMarkedPeriodStep("forward")}
                    disabled={this.state.markedPeriodLength === 0 ? true : false}
                  >
                    <ArrowForwardIosRoundedIcon />
                  </IconButton>
                </span>
              </Tooltip>

              <FormControlLabel
                style={{marginLeft:"-6px"}}
                label={
                  <div className={classes.SwitchLabel}>
                    {this.props.intl.formatMessage({id: "chart.period_freeze_short"})}
                  </div>
                }
                labelPlacement="top"
                control={
                  <Tooltip
                    title={this.props.intl.formatMessage({
                      id: "chart.period_freeze"
                    })}
                  >
                    <Switch
                      checked={this.state.freeze}
                      onChange={this.toggleFreeze}
                      color="primary"
                    />
                  </Tooltip>
                }
              />
              <FormControlLabel
                style={{marginLeft:"-6px"}}
                label={
                  <div className={classes.SwitchLabel}>
                    {this.props.intl.formatMessage({id: "chart.separate_calculated_short"})}
                  </div>
                }
                labelPlacement="top"
                control={
                  <Tooltip
                    title={this.props.intl.formatMessage({
                      id: "chart.separate_calculated"
                    })}
                  >
                    <Switch
                      checked={this.state.separateCalculated}
                      onChange={this.toggleSeparateCalculated}
                      color="primary"
                    />
                  </Tooltip>
                }
              />
              <FormControlLabel
                style={{marginLeft:"-6px"}}
                label={
                  <div className={classes.SwitchLabel}>
                    {this.props.intl.formatMessage({id: "chart.toggle_chart_hover_label_short"})}
                  </div>
                }
                labelPlacement="top"
                control={
                  <Tooltip
                    title={this.props.intl.formatMessage({
                      id: "chart.toggle_chart_hover_label"
                    })}
                  >
                    <Switch
                      checked={this.state.chartHoverLabel}
                      onChange={this.toggleChartHoverLabel}
                      color="primary"
                    />
                  </Tooltip>
                }
              />
              {/* <FormControlLabel
                style={{marginLeft:"-6px"}}
                label={
                  <div className={classes.SwitchLabel}>
                    {this.props.intl.formatMessage({id: "chart.toggle_energy_chart_unit_short"})}
                  </div>
                }
                labelPlacement="top"
                control={
                  <Tooltip
                    title={this.props.intl.formatMessage({
                      id: "chart.toggle_energy_chart_unit"
                    })}
                  >
                    <Switch
                      checked={this.state.usekW}
                      onChange={this.toggleEnergyChartUnit}
                      color="primary"
                    />
                  </Tooltip>
                }
              /> */}
              {/* <FormControlLabel
                style={{marginLeft:"-6px"}}
                label={
                  <div className={classes.SwitchLabel}>
                    {this.props.intl.formatMessage({id: "chart.toggle_flow_chart_unit_short"})}
                  </div>
                }
                labelPlacement="top"
                control={
                  <Tooltip
                    title={this.props.intl.formatMessage({
                      id: "chart.toggle_flow_chart_unit"
                    })}
                  >
                    <Switch
                      checked={this.state.usem3ph}
                      onChange={this.toggleFlowChartUnit}
                      color="primary"
                    />
                  </Tooltip>
                }
              /> */}
            </React.Fragment>
          </Grid>
          <Grid item xs={5} className={classes.AlignRight}>
            { this.state.showRevalidateButton &&
              <>
                <Tooltip title={this.props.intl.formatMessage({ id: "chart.validate" })}>
                  <IconButton 
                    className={classes.MarginRight} 
                    onClick={this.handleOpenValidateDialog}
                  >
                    <SyncIcon />
                  </IconButton>
                </Tooltip>
                <Dialog
                  open={this.state.openValidateDialog}
                  onClose={this.handleCloseValidateDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {this.props.intl.formatMessage({ id: "chart.validate" })}
                  </DialogTitle>
                  <DialogContent>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      {createDiagramsDatePicker(
                        this.props.intl.formatMessage,
                        "chart.validate_from_date",
                        value => this.handleDateInput("validateDataFrom", value),
                        this.state.validateDataFrom
                      )}
                      {createDiagramsDatePicker(
                        this.props.intl.formatMessage,
                        "chart.validate_to_date",
                        value => this.handleDateInput("validateDataTo", value),
                        this.state.validateDataTo
                      )}
                    </MuiPickersUtilsProvider>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleCloseValidateDialog}>
                      {this.props.intl.formatMessage({ id: "chart.validate_cancel" })}
                    </Button>
                    <Button onClick={this.revalidateData} autoFocus>
                      {this.props.intl.formatMessage({ id: "chart.validate_submit" })}
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            }
            { !this.state.showRevalidateButton &&
              <Tooltip title={this.props.intl.formatMessage({ id: "chart.swap_date" })} placement="bottom">
                <span>
                  <IconButton
                    className={classes.MarginRight}
                    onClick={this.swapViewDate}
                    color={this.state.showRefPeriodInDiagram === false ? "default" : "secondary"}
                    disabled={!(this.props.meteringpoint.reference_from &&
                      this.props.meteringpoint.reference_to)}                
                  >
                    <SwapHorizontalCircleIcon />
                  </IconButton>
                </span>
              </Tooltip>            
            }
            <ExportData
              diagramDataIncludeTo={this.state.diagramDataIncludeTo}
              diagramDataIncludeFrom={this.state.diagramDataIncludeFrom}
              meteringpoint={this.props.meteringpoint}
              context={this.props.context}
            />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              {createDiagramsDatePicker(
                this.props.intl.formatMessage,
                "chart.from_date",
                value => this.handleDateInput("diagramDataIncludeFrom", value),
                this.state.diagramDataIncludeFrom
              )}
              {createDiagramsDatePicker(
                this.props.intl.formatMessage,
                "chart.to_date",
                value => this.handleDateInput("diagramDataIncludeTo", value),
                this.state.diagramDataIncludeTo
              )}
            </MuiPickersUtilsProvider>
            <Tooltip
              title={this.props.intl.formatMessage({
                id: "update"
              })}
              placement="bottom"
            >
              <IconButton onClick={() => this.fetchDiagramData()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            {this.state.nrDataSetsWaiting > 0 ? 
                <div style={{height:4, marginLeft: "30px", fontSize: "10px"}}>
                  <LinearProgress /> 
                </div>
              : 
                <>
                {
                  (this.state.missingInfoEnergy.notValidatedRatio > 0 || this.state.missingInfoFlow.notValidatedRatio > 0) ?
                    <div className={classes.WarningAlert} >
                      <WarningIcon/><span className={classes.WarningAlertText}>{this.props.intl.formatMessage({ id: "chart.not_all_data_validated" })}</span>
                    </div>
                  :
                    <span className={classes.WarningAlertText}>{this.props.intl.formatMessage({ id: "chart.lastestValidated" })}: {this.state.missingInfoEnergy.latestValidated.substring(0, 10) }.</span>
                }
                </>
              }
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={this.state.chartWidths}>
                <ChartEFSignature
                  chartHeight={this.state.chartHeight}
                  data={this.state.energyReadings}
                  alarms={this.state.energyReadingsAlarms}
                  markedStart={this.state.markedPeriodStart}
                  markedEnd={this.state.markedPeriodEnd}
                  markedLength={this.state.markedPeriodLength}
                  useAltUnit={this.state.usekW}
                  waitingForData={waitingForData}
                  chartSymbol={this.state.chartSymbol}
                  chartSymbolSize={this.state.chartSymbolSize}
                  colorPattern={this.getColorPattern(this.state.themeName)}
                  separateCalculated={this.state.separateCalculated}
                  title="energy"
                  chartHoverLabel={this.state.chartHoverLabel}
                  pOnHover={this.syncHover.bind(this)}
                  pOnRelayout={this.syncZoom.bind(this)}
                  selectedPlots={this.state.selectedPlotsErg}
                  labelPlots={this.state.labelPlotsErg}
                  notifyNewSelection={this.handleNewSelectionErg}
                  zerolines={this.state.themeName !== "classic"}
                  showBadData={this.state.showBadData} 
                />
              </Grid>
              <Grid item xs={12-this.state.chartWidths}>
                <ChartEFTime
                  chartHeight={this.state.chartHeight}
                  data={this.state.energyReadings}
                  alarms={this.state.energyReadingsAlarms}
                  markedStart={this.state.markedPeriodStart}
                  markedEnd={this.state.markedPeriodEnd}
                  markedLength={this.state.markedPeriodLength}
                  useAltUnit={this.state.usekW}
                  waitingForData={waitingForData}
                  chartSymbol={this.state.chartSymbol}
                  chartSymbolSize={this.state.chartSymbolSize}
                  colorPattern={this.getColorPattern(this.state.themeName)}
                  title="energy"
                  chartHoverLabel={this.state.chartHoverLabel}
                  pOnHover={this.syncHover.bind(this)}
                  pOnRelayout={this.syncZoom.bind(this)}
                  protocolTimestamps={this.props.protocolTimestamps}
                  selectedPlots={this.state.selectedPlotsErg}
                  labelPlots={this.state.labelPlotsErg}
                  notifyNewSelection={this.handleNewSelectionErg}
                  zerolines={this.state.themeName !== "classic"}
                  showBadData={this.state.showBadData}
                  missingInfo={this.state.missingInfoEnergy} 
                />
              </Grid>
              <Grid item xs={this.state.chartWidths}>
                <ChartEFSignature
                  chartHeight={this.state.chartHeight}
                  data={this.state.flowReadings}
                  alarms={this.state.flowReadingsAlarms}
                  markedStart={this.state.markedPeriodStart}
                  markedEnd={this.state.markedPeriodEnd}
                  markedLength={this.state.markedPeriodLength}
                  useAltUnit={this.state.usem3ph}
                  waitingForData={waitingForData}
                  chartSymbol={this.state.chartSymbol}
                  chartSymbolSize={this.state.chartSymbolSize}
                  colorPattern={this.getColorPattern(this.state.themeName)}
                  separateCalculated={this.state.separateCalculated}
                  title="flow"
                  chartHoverLabel={this.state.chartHoverLabel}
                  pOnHover={this.syncHover.bind(this)}
                  pOnRelayout={this.syncZoom.bind(this)}
                  selectedPlots={this.state.selectedPlotsVol}
                  labelPlots={this.state.labelPlotsVol}
                  notifyNewSelection={this.handleNewSelectionVol}
                  zerolines={this.state.themeName !== "classic"}
                  showBadData={this.state.showBadData} 
                />
              </Grid>
              <Grid item xs={12-this.state.chartWidths}>
                <ChartEFTime
                  chartHeight={this.state.chartHeight}
                  data={this.state.flowReadings}
                  alarms={this.state.flowReadingsAlarms}
                  markedStart={this.state.markedPeriodStart}
                  markedEnd={this.state.markedPeriodEnd}
                  markedLength={this.state.markedPeriodLength}
                  useAltUnit={this.state.usem3ph}
                  waitingForData={waitingForData}
                  chartSymbol={this.state.chartSymbol}
                  chartSymbolSize={this.state.chartSymbolSize}
                  colorPattern={this.getColorPattern(this.state.themeName)}
                  title="flow"
                  chartHoverLabel={this.state.chartHoverLabel}
                  pOnHover={this.syncHover.bind(this)}
                  pOnRelayout={this.syncZoom.bind(this)}
                  protocolTimestamps={this.props.protocolTimestamps}
                  selectedPlots={this.state.selectedPlotsVol}
                  labelPlots={this.state.labelPlotsVol}
                  notifyNewSelection={this.handleNewSelectionVol}
                  zerolines={this.state.themeName !== "classic"}
                  showBadData={this.state.showBadData} 
                  missingInfo={this.state.missingInfoFlow} 
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={this.state.chartWidths}>
                <ChartDSignature
                  chartHeight={this.state.chartHeight}
                  data={this.state.tempReadings}
                  markedStart={this.state.markedPeriodStart}
                  markedEnd={this.state.markedPeriodEnd}
                  markedLength={this.state.markedPeriodLength}
                  waitingForData={waitingForData}
                  chartSymbol={this.state.chartSymbol}
                  chartSymbolSize={this.state.chartSymbolSize}
                  colorPattern={this.getColorPattern(this.state.themeName)}
                  title={"chart.delta_T_diagram"}
                  chartHoverLabel={this.state.chartHoverLabel}
                  maxTempLimit={maxTempLimit}
                  pOnHover={this.syncHover.bind(this)}
                  pOnRelayout={this.syncZoom.bind(this)}
                  zerolines={this.state.themeName !== "classic"}
                />
              </Grid>
              <Grid item xs={12-this.state.chartWidths}>
                <ChartDTime
                  chartHeight={this.state.chartHeight}
                  data={this.state.tempReadings}
                  markedStart={this.state.markedPeriodStart}
                  markedEnd={this.state.markedPeriodEnd}
                  markedLength={this.state.markedPeriodLength}
                  waitingForData={waitingForData}
                  chartSymbol={this.state.chartSymbol}
                  chartSymbolSize={this.state.chartSymbolSize}
                  colorPattern={this.getColorPattern(this.state.themeName)}
                  title={"chart.delta_T_diagram"}
                  chartHoverLabel={this.state.chartHoverLabel}
                  maxTempLimit={maxTempLimit}
                  pOnHover={this.syncHover.bind(this)}
                  pOnRelayout={this.syncZoom.bind(this)}
                  zerolines={this.state.themeName !== "classic"}
                  protocolTimestamps={this.props.protocolTimestamps}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(injectIntl(Charts, { forwardRef: true }));
