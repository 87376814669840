import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import axios from "axios";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import ChartForDebet from "./ChartForDebet";
import Cookies from "js-cookie";

import {
  withStyles,
  Typography,
  Paper,
  TextField,
  Button,
  Divider,
  Table,
  Grid,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress
} from "@material-ui/core";

import styles from "../styles";
import ErrorMessage from "./ErrorMessage";

class DifferenceCalculation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meteringPointId: props.meteringPointId ? props.meteringPointId : null,
      errorOpen: false,
      energy_data: {},
      flow_data: {},
      showLoadingIcon: false,
      themeName: this.getThemeNameFromCookie()
    };
    if (props.location.state) {
      this.state.referenceFrom = new Date(props.location.state.referenceFrom);
      this.state.referenceTo = new Date(props.location.state.referenceTo);
      this.state.appliedFrom = new Date(props.location.state.appliedFrom);
      this.state.appliedTo = new Date(props.location.state.appliedTo);
    } else {
      this.state.referenceFrom = new Date(2017, 1, 1);
      this.state.referenceTo = new Date(2017, 12, 31);
      this.state.appliedFrom = new Date(2018, 1, 1);
      this.state.appliedTo = new Date(2018, 12, 31);
    }
  }

  formatValue = (property, value) => {
    value = value.toFixed(3);
    switch (property) {
      case "energy":
        return value + " MWh";
      case "flow":
        return value + " m³";
      default:
        return value;
    }
  };

  formatMissingValue = (property, value, missingValue) => {
    value = value.toFixed(3);
    missingValue = missingValue.toFixed(3);
    switch (property) {
      case "energy":
        return value + " (" + missingValue + ") MWh ";
      case "flow":
        return value + " (" + missingValue + ") m³";
      default:
        return value;
    }
  }

  splitAndSave = data => {
    let energy_data = {};
    let flow_data = {};
    data.forEach(function(item) {
      if (item.property === "energy") {
        energy_data = item;
      }
      if (item.property === "flow") {
        flow_data = item;
      }
    });
    this.setState({ energy_data: energy_data, flow_data: flow_data });
  };

  getThemeNameFromCookie = () => {
    let name = Cookies.get("k2_themename");
    if (typeof name === "undefined") {
      name = "default";
    }
    return name;
  };

  /**
   * Get the color dict based on the theme name, group, ref and reg should be the same color,
   * but with a difference in grade/intensity,
   * group - dark, reg - medium, reg - light
   *
   * @param {*} theme
   */
  getColorPattern = theme => {
    if (typeof theme === "undefined") {
      theme = "default";
    }
    let colorPattern = {};
    if (theme === "classic") {
      colorPattern = {
        measured: "rgb(50,50,242)",
        calculated: "rgb(18,157,0)",
        missing: "rgb(84,84,84,0)",
        bgcolor: "rgb(204,204,204)"
      };
    }
    if (theme === "default") {
      colorPattern = {
        measured: "rgb(50,50,242)",
        calculated: "rgb(208,50,50)",
        missing: "rgb(84,84,84,0)",        
        bgcolor: "rgb(255,255,255)"
      };
    }

    return colorPattern;
  };

  calculate = () => {
    this.setState({ result: null, showLoadingIcon: true });

    var params = {
      metering_point_id: this.state.meteringPointId,
      reference_from: this.state.referenceFrom.toLocaleDateString("sv-SE"),
      reference_to: this.state.referenceTo.toLocaleDateString("sv-SE"),
      applied_from: this.state.appliedFrom.toLocaleDateString("sv-SE"),
      applied_to: this.state.appliedTo.toLocaleDateString("sv-SE")
    };

    var thiz = this;
    this.props.context.keycloak
      .updateToken(5)
      .success(function() {
        var headers = {
          Authorization: "Bearer " + thiz.props.context.keycloak.token
        };
        axios
          .get(thiz.props.context.config.regressionAPI + "/transpose", {
            headers,
            params
          })
          .then(res => {
            thiz.splitAndSave(res.data);
            thiz.setState({ result: res.data, showLoadingIcon: false });
            thiz.props.history.push("/diffcalculation/" + thiz.state.meteringPointId);
          })
          .catch(error => {
            console.log(error);
            let errMsg;
            if (error.response.status === 400 &&
                error.response.data.subject === "No meter readings found") {
              errMsg = thiz.props.intl.formatMessage({
                id: "diffcalculation.error_message_no_readings"
              }) + params.metering_point_id
            } else {
              errMsg = (error.response && error.response.status) ?
                        error.response.status: error;
            }
            thiz.setState({
              errorOpen: true,
              errorMessage: thiz.props.intl.formatMessage({
                id: "diffcalculation.error_message"
              }) + " (" + errMsg + ")",
              showLoadingIcon: false
            });
          });
      })
      .error(function() {
        thiz.props.context.keycloak.login();
      });
  };

  handleInput = (id, value) => {
    this.setState({ [id]: new Date(value) });
  };

  validateInput = () => {
    return (
      Boolean(this.state.meteringPointId) &
      Boolean(this.state.referenceFrom) &
      Boolean(this.state.referenceTo) &
      Boolean(this.state.appliedFrom) &
      Boolean(this.state.appliedTo) &
      (this.state.referenceFrom <= this.state.referenceTo) &
      (this.state.appliedFrom <= this.state.appliedTo)
    );
  };

  handleErrorClose = () => {
    this.setState({ errorOpen: false });
  };

  createDatePicker(id, value) {
    return (
      <KeyboardDatePicker
        id={id}
        value={value}
        className={this.props.classes.DiffCalcDatePicker}
        allowKeyboardControl
        autoOk
        disableFuture
        invalidDateMessage={this.props.intl.formatMessage({
          id: "invalid_date_message"
        })}
        minDateMessage={this.props.intl.formatMessage({
          id: "min_date_message"
        })}
        maxDateMessage={this.props.intl.formatMessage({
          id: "max_date_message"
        })}
        label={this.props.intl.formatMessage({
          id: "diffcalculation." + id
        })}
        InputLabelProps={{ shrink: true }}
        format="YYYY-MM-DD"
        onChange={value => this.handleInput(id, value)}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const theme = this.getColorPattern(this.state.themeName);
    return (
      <React.Fragment>
        <ErrorMessage
          open={this.state.errorOpen}
          onClose={this.handleErrorClose}
          message={this.state.errorMessage}
          duration={5000}
        />
        <Typography variant="h5" color="textSecondary" className={classes.TypographyHeight}>
          <FormattedMessage id="diffcalculation" />
        </Typography>
        <Paper className={classes.Paper}>
          <div>
            <TextField
              onChange={event => this.setState({ meteringPointId: event.target.value })}
              className={this.props.classes.DiffCalcMeteringPointIdField}
              component={"span"}
              id="meteringPointId"
              placeholder=""
              label={this.props.intl.formatMessage({
                id: "metering_points.metering_point"
              })}
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              defaultValue={this.state.meteringPointId}
            />
          </div>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <div>
              {this.createDatePicker("referenceFrom", this.state.referenceFrom)}
              {this.createDatePicker("referenceTo", this.state.referenceTo)}
            </div>
            <div>
              {this.createDatePicker("appliedFrom", this.state.appliedFrom)}
              {this.createDatePicker("appliedTo", this.state.appliedTo)}
            </div>
          </MuiPickersUtilsProvider>
          <Button onClick={this.calculate} variant="outlined" disabled={!this.validateInput()}>
            <FormattedMessage id="diffcalculation.calculate" />
          </Button>
          {this.state.showLoadingIcon === true ? (
            <Grid container justify="center">
              <Grid item xs={12}>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          {this.state.result ? (
            <React.Fragment>
              <Divider className={classes.Divider} />
              <Typography variant="h6" color="textSecondary">
                <FormattedMessage id="diffcalculation.result" />
              </Typography>
              <Table className={classes.DifferenceCalculationTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="alarm.meter_reading_property" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="diffcalculation.appliedFrom" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="diffcalculation.appliedTo" />
                    </TableCell>
                    <TableCell align="right">
                      <FormattedMessage id="diffcalculation.measured_consumption" />
                    </TableCell>
                    <TableCell align="right">
                      <FormattedMessage id="diffcalculation.calculated_consumption_with_missing" />
                    </TableCell>
                    <TableCell align="right">
                      <FormattedMessage id="diffcalculation.difference" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.result.map(row => {
                    return (
                      <TableRow
                        key={this.props.intl.formatMessage({
                          id: row.property
                        })}
                      >
                        <TableCell>
                          {this.props.intl.formatMessage({
                            id: row.property
                          })}
                        </TableCell>
                        <TableCell>{row.applied_from}</TableCell>
                        <TableCell>{row.applied_to}</TableCell>
                        <TableCell align="right">{this.formatValue(row.property, row.measured_consumption)}</TableCell>
                        <TableCell align="right">
                          {this.formatMissingValue(row.property, row.calculated_consumption, row.calculated_missing_consumption)}
                        </TableCell>
                        <TableCell align="right">{this.formatValue(row.property, row.difference)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <Divider className={classes.Divider} />
              <br />
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <ChartForDebet
                    data={this.state.energy_data.consumption_values}
                    theme={theme}
                    diaTitle={this.props.intl.formatMessage({ id: "chart.energy_diagram" })}
                    yAxisTitle={this.props.intl.formatMessage({ id: "chart.energy_per_day" })}
                    xAxisTitle={this.props.intl.formatMessage({ id: "chart.temperature_outdoor_daily_average" })}
                    groupTitleConsumed={this.props.intl.formatMessage({ id: "diffcalculation.measured_consumption" })}
                    groupTitleCalculated={this.props.intl.formatMessage({
                      id: "diffcalculation.calculated_consumption"
                    })}
                    groupTitleMissingConsumed="Saknad konsumtion"
                  />
                </Grid>
                <Grid item sm={6}>
                  <ChartForDebet
                    data={this.state.flow_data.consumption_values}
                    theme={theme}
                    diaTitle={this.props.intl.formatMessage({ id: "chart.flow_diagram" })}
                    yAxisTitle={this.props.intl.formatMessage({ id: "chart.flow_per_day" })}
                    xAxisTitle={this.props.intl.formatMessage({ id: "chart.temperature_outdoor_daily_average" })}
                    groupTitleConsumed={this.props.intl.formatMessage({ id: "diffcalculation.measured_consumption" })}
                    groupTitleCalculated={this.props.intl.formatMessage({
                      id: "diffcalculation.calculated_consumption"
                    })}
                    groupTitleMissingConsumed="Saknad konsumtion"
                  />
                </Grid>
              </Grid>
              <br />
              {
                this.state.result[0] && this.state.result[0].missing_weather_days.length > 0 &&
                <>
                  <Divider className={classes.Divider} />                
                  <Typography variant="p">
                    <FormattedMessage id="diffcalculation.missing_weather_data" className={classes.SmallParagraph}/>
                    <p className={classes.SmallParagraph}>{
                      this.state.result[0].missing_weather_days.map(w => w.substring(0, 10)).sort().join(", ")
                    }</p>
                  </Typography>                
                </>
              }
            </React.Fragment>
          ) : (
            <div />
          )}
        </Paper>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(injectIntl(DifferenceCalculation)));
