import React, { useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { withStyles, Typography, Grid, Paper, Toolbar, Button, ButtonGroup, CircularProgress } from "@material-ui/core";
import useStatus from "../services/useStatus";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { useIntl } from "react-intl";
import styles from "../styles";
import StatusDaysButtonGroup from "./StatusDaysButtonGroup";
import createPlotlyComponent from "react-plotly.js/factory";
import Plotly from "plotly.js-basic-dist-min";
const Plot = createPlotlyComponent(Plotly);
import ErrorMessage from "./ErrorMessage";
import { Redirect } from "react-router";

const errorColumns = [
  {
    name: "metering_point_id",
    label: <FormattedMessage id="status.metering_point_id" />,
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "error_code",
    label: <FormattedMessage id="status.error_code" />,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "desc",
    label: <FormattedMessage id="status.description" />,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "timestamp",
    label: <FormattedMessage id="status.day" />,
    options: {
      filter: true,
      sort: true,
    },
  },
];

const missingColumns = [
  {
    name: "metering_point_id",
    label: <FormattedMessage id="status.metering_point_id" />,
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "address.street_address",
    label: <FormattedMessage id="status.address" />,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "day",
    label: <FormattedMessage id="status.day" />,
    options: {
      filter: true,
      sort: true,
    },
  },
];

const tableOptions = {
  filterType: "multiselect",
  selectableRows: "none",
  responsive: "scrollMaxHeigh",
  elevation: 0,
  rowsPerPageOptions: [10, 20, 50],
  viewColumns: true,
  toolbar: true,
};

let gDay = moment().subtract(1, "days").format("YYYY-MM-DD");
let gPage = 0;

const Status = ({ classes }) => {
  const intl = useIntl();
  // const [currentDay, setcurrentDay] = useState("2023-02-01");
  const [currentDay, setcurrentDay] = useState(moment().subtract(1, "days").format("YYYY-MM-DD"));
  const [showMissing, setShowMissing] = useState(true);
  const selectedColor = "rgb(31, 119, 180)";
  const notSelectedColor = 'rgba(204,204,204,1)';

  const { dayStatus, prevDays, setnDays, isLoading, nDays, formatStatusLabels, errorMessage } = useStatus(currentDay);

  return (
    <React.Fragment>
        <ErrorMessage open={errorMessage.length > 0} message={errorMessage} />
      <StatusDaysButtonGroup
        isLoading={isLoading}
        setnDays={(n) => {
          setcurrentDay(moment().subtract(1, "days").format("YYYY-MM-DD"));
          setnDays(n);
        }}
        nDays={nDays}
      />
      <Grid container>
        <Grid item xs={12} className={classes.StatusHeader}>
          <Typography variant="h5" color="textSecondary">
            <FormattedMessage id="status.reading_status" /> {currentDay}
          </Typography>
          <p>
            {dayStatus[currentDay] ? dayStatus[currentDay].nMeasures : 0}{" "}
            <FormattedMessage id="status.loaded_readings" />
          </p>
        </Grid>
        <Grid item xs={4} className={classes.ChartGrids}>
          <h5 className={classes.ChartHeaders}>
            <FormattedMessage id="status.distribution" /> {currentDay}
          </h5>
          {dayStatus[currentDay] && (
            <Plot
              data={[
                {
                  values: [
                    dayStatus[currentDay].hasNoMeasures?.length,
                    dayStatus[currentDay].nMeasures - dayStatus[currentDay].nErrors,
                    ...Object.values(dayStatus[currentDay].categories),
                  ],
                  labels: formatStatusLabels({ intl, dayStatus, yesterday: currentDay }),
                  type: "pie",
                },
              ]}
              layout={{
                height: 300,
                width: 400,
                margin: { t: 25, l: 50, b: 5, r: 5 },
              }}
            />
          )}
        </Grid>
        <Grid item xs={4} className={classes.ChartGrids}>
          <h5 className={classes.ChartHeaders}>
            <FormattedMessage id="status.errors_per_day" />
          </h5>
          <Plot
            data={[
              {
                y: prevDays.map((day) => (dayStatus[day] ? dayStatus[day].nErrors : 0)),
                x: prevDays,
                type: "bar", 
                marker: {
                  color: prevDays.map((day) => day === currentDay && !showMissing ? selectedColor : notSelectedColor)
                }                             
              },
            ]}
            layout={{
              height: 300,
              width: 500,
              xaxis: {
                type: "category",
                fixedrange: true,
              },
              yaxis: {
                fixedrange: true,
              },
              margin: { t: 25, l: 30, b: 60, r: 5 },
            }}
            onClick={(e) => {
              setcurrentDay(e.points[0].label);
              gDay = e.points[0].label;
              setShowMissing(false);
            }}
          />
        </Grid>
        <Grid item xs={4} className={classes.ChartGrids}>
          <h5 className={classes.ChartHeaders}>
            <FormattedMessage id="status.total_number_of_metering_point_without_values" />
          </h5>
          <Plot
            data={[
              {
                y: prevDays.map((day) => (dayStatus[day] ? dayStatus[day].hasNoMeasures?.length : 0)),
                x: prevDays,
                type: "bar",
                marker: {
                  color: prevDays.map((day) => day === currentDay && showMissing ? selectedColor : notSelectedColor)
                }
                // marker:{
                //   color: ['rgba(204,204,204,1)', 'rgba(222,45,38,0.8)', 'rgba(204,204,204,1)', 'rgba(204,204,204,1)', 'rgba(204,204,204,1)']
                // },                  
              },
            ]}
            layout={{
              height: 300,
              width: 500,
              xaxis: {
                type: "category",
                fixedrange: true,
              },
              yaxis: {
                fixedrange: true,
              },
              margin: { t: 25, l: 45, b: 60, r: 5 },
            }}
            onClick={(e) => {
              gDay = e.points[0].label;
              setcurrentDay(e.points[0].label);
              setShowMissing(true);
            }}
          />
        </Grid>
        {!showMissing && (
          <Grid item xs={12} className={classes.StatusLists}>
            {dayStatus[currentDay] && dayStatus[currentDay].error_list.length > 0 ? (
              <>
                <Typography variant="h6" color="textSecondary">
                  <span>
                    <FormattedMessage id="status.error_list" /> { currentDay }
                  </span>
                </Typography>
                <Paper>
                  <MUIDataTable
                    className={classes.PointerCursor}
                    data={dayStatus[currentDay].error_list}
                    columns={errorColumns}
                    options={{
                      ...tableOptions,
                      onRowClick: (d) => window.open("/meteringpoint/" + d[0], "_blank"),
                      page: gPage,
                      onChangePage: (n) => (gPage = n),
                    }}
                  />
                </Paper>
              </>
            ) : (
              <FormattedMessage id="status.no_errors_reported" />
            )}
          </Grid>
        )}
        {showMissing && (
          <Grid item xs={12} className={classes.StatusLists}>
            {dayStatus[currentDay] && dayStatus[currentDay].hasNoMeasures.length > 0 ? (
              <>
                <Typography variant="h6" color="textSecondary">
                  <span>
                    <FormattedMessage id="status.error_list_missing_measures" /> { currentDay }
                  </span>
                </Typography>
                <Paper>
                  <MUIDataTable
                    className={classes.PointerCursor}
                    data={dayStatus[currentDay].hasNoMeasures}
                    columns={missingColumns}
                    options={{
                      ...tableOptions,
                      onRowClick: (d) => window.open("/meteringpoint/" + d[0], "_blank"),
                      page: gPage,
                      onChangePage: (n) => (gPage = n),
                    }}
                  />
                </Paper>
              </>
            ) : (
              <FormattedMessage id="status.no_missing_meter_readings" />
            )}
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default withStyles(styles)(injectIntl(Status));
