const messages = {
  en: {
    energy: "Energy",
    flow: "Volume",
    aboutK2: "About K2",
    account: "Account",
    language: "Language",
    login: "Login",
    logout: "Log out",
    manage_users: "Manage users",
    dashboard: "Dashboard",
    alarms: "Alarms",
    metering_points: "Metering points",
    metering_point: "Metering point",
    diffcalculation: "Diff calculation",
    cooling: "Cooling",
    configuration: "Configuration",
    day: "day",
    week: "week",
    weeks: "weeks",
    month: "month",
    months: "months",
    year: "year",
    invalid_date_message: "Invalid date format",
    max_date_message: "Please choose an earlier date",
    min_date_message: "Please choose a later date",
    add: "Add",
    apply: "Apply",
    cancel: "Cancel",
    delete: "Remove",
    ok: "Ok",
    save: "Save",
    search: "Search",
    update: "Update",
    default: "Default",
    tags: "Tags",
    "tags.select": "Select...",
    "tags.add_new": "Add new tag",
    "tags.select_or_create": "Select or create new...",
    "alarm.number_of_alarms": "Number of alarms",
    "alarm.accumulated_deviation": "Accumulated deviation",
    "alarm.normalized_accumulated_deviation": "Normalized accumulated deviation",
    "alarm.metering_point_status": "Metering point status",
    "alarm.newest_note": "Newest note",
    "alarm.delta_t": "Flow-weighted Delta-T",
    "alarm.overflow": "Overflow",
    "alarm.return_temperature": "Return temp",
    "alarm.flow_temperature": "Flow temp",
    "alarm.meter_reading_property": "Property",
    "alarm.property_energy": "Energy",
    "alarm.property_flow": "Volume",
    "alarm.sort": "Sort",
    "alarm.sort.accumulated_deviation":
      "Sort (Accumulated deviation is the sum of the absolute amount of alerted deviations during the selected period)",
    "alarm.sort.accumulated_opposite":
      "Sort (Opposite accumulated deviation is the sum of the alarmed deviations (with a sign) during the selected period.)",
    "alarm.sort.normalized_accumulated_deviation":
      "Sort (Normalized accumulated deviation means that the deviation is measured against the measuring point's total consumption during the selected period.)",
    "alarm.rows_per_page": "Rows per page",
    "alarm.of": "of",
    "alarm.download_csv": "Download CSV",
    "alarm.filter_table": "Filter table",
    "alarm.filter_table_toggle_tooltip": "Filter tags based on AND or OR between them",
    "alarm.deviation": "Deviation",
    "alarm.status": "Status",
    "alarm.all": "All",
    "alarm.all_description": "Shows the sum of all deviations during the selected period",
    "alarm.higher": "Higher",
    "alarm.higher_description":
      "Shows the sum of all deviations where the measured value is higher than the calculated",
    "alarm.lower": "Lower",
    "alarm.lower_description": "Shows the sum of all deviations where the measured value is lower than the calculated",
    "alarm.none": "<None>",
    "alarm.loading": "Loading alarms...",
    "alarm.filter_metering_point": "Filter by metering points/address",
    "alarm.error_message_fetching_data": "Error fetching data",
    "alarm.accumulated_opposite": "Opposite accumulated",
    "alarm.last_updated": "Last updated: ",
    "alarm.update": "Refetch alarms",
    "alarm.rebuild": "Reconstruct alarm lists (in the background)",
    "alarm.rebuilding": "New alarm lists are being constructed. You can continue working in the meantime.",
    "alarm.rebuild_done": "New alarm lists have been constructed.",
    "metering_points.not_found": "There exists no metering point with the name ",
    "metering_points.fetch_error": "Something went wrong trying to fetch metering point ",
    "metering_points.note": "Note",
    "metering_points.note.user": "User",
    "metering_points.note.timestamp": "Time",
    "metering_points.add_note_button": "Add",
    "metering_points.write_note_here": "Write your note here",
    "metering_points.show_notes": "Show notes",
    "metering_points.show_labels": "Show labels",
    "metering_points.add_new_label": "Add new label",
    "metering_points.labels_id": "id",
    "metering_points.labels_description": "description",
    "metering_points.labels_tags": "tag",
    "metering_points.labels_creator": "creator",
    "metering_points.labels_create_date": "create date",
    "metering_points.ref_period_from": "Reference period, from", //Contains no-break spaces
    "metering_points.ref_period_to": "Reference period, to", //Contains no-break spaces
    "metering_points.altitude": "Altitude",
    "metering_points.latitude": "Latitude",
    "metering_points.type": "Type",
    "metering_points.longitude": "Longitude",
    "metering_points.address": "Address",
    "metering_points.manufacturer": "Manufacturer",
    "metering_points.meter_id": "Meter ID",
    "metering_points.model": "Model",
    "metering_points.reading_frequency": "Reading interval (s)",
    "metering_points.other": "Other",
    "metering_points.status": "Status",
    "metering_points.metering_point": "Metering Point",
    "metering_points.metering_points": "Metering Points",
    "metering_points.tag_key": "Key",
    "metering_points.tag_val": "Val",
    "metering_points.street": "Street",
    "metering_points.postal_code": "Postal code",
    "metering_points.city": "City",
    "metering_points.weather_station_id": "Weather station",
    "metering_points.weather_station_agency": "Weather station agency",
    "metering_points.download_csv": "Download CSV",
    "metering_points.download_csv_from_date": "Start date",
    "metering_points.download_csv_to_date": "End date",
    "metering_points.download_show_data": "Show data",
    "metering_points.export.aggregated_data": "Aggregated data",
    "metering_points.export.metering_point_id": "Metering point ID",
    "metering_points.export.property": "Property",
    "metering_points.export.timestamp": "Timestamp",
    "metering_points.export.consumption_from": "Consumption from",
    "metering_points.export.value": "Value",
    "metering_points.export.unit_of_measure": "Unit of measure",
    "metering_points.export.reading_status": "Reading status",
    "metering_points.export.consumption_to": "Consumption to",
    "metering_points.export.consumption_value": "Consumption value",
    "metering_points.export.calculated_value": "Calculated value",
    "metering_points.export.calculated_normalized_value": "Calculated Normalized Value",
    "metering_points.export.calculated_deviation_type": "Calculated Deviation Type",
    "metering_points.export.calculated_deviation_level": "Calculated Deviation Level",
    "metering_points.export.temperature": "Outdoor temperature",
    "metering_points.export.energy": "Energy consumption (MWh)",
    "metering_points.export.ref_energy": "Ref energy",
    "metering_points.export.energy_value": "Energy value",
    "metering_points.export.energy_status": "Energy status",
    "metering_points.export.flow": "Volume consumption (m³)",
    "metering_points.export.ref_flow": "Ref volume",
    "metering_points.export.flow_value": "Volume value",
    "metering_points.export.flow_status": "Volume status",
    "metering_points.export.measured": "Measured",
    "metering_points.export.calculated": "Calculated",
    "metering_points.status_under_surveillance": "Under surveillance",
    "metering_points.status_under_investigation": "Under investigation",
    "metering_points.status_investigated": "Investigated",
    "metering_points.status_forget": "Forgotten",
    "metering_points.status_ok": "Ok",
    "metering_points.changed_status": "Changed status to",
    "metering_points.status_": "Reset status",
    "metering_points.status_none": "<None>",
    "metering_points.meter_change.add": "Register new meter change",
    "metering_points.meter_change_type": "Meter change type",
    "metering_points.meter_change_date": "Latest meter change",
    "metering_points.meter_change_serial_number": "Meter change serial number", //Contains no-break spaces
    "metering_points.meter_change_comment": "Meter change comment",
    "metering_points.meter_change_type_add": "Meter change type",
    "metering_points.meter_change_date_add": "Latest meter change",
    "metering_points.meter_change_serial_number_add": "Meter change serial number", //Contains no-break spaces
    "metering_points.meter_change_comment_add": "Meter change comment",
    "metering_points.meter_change.addButton": "Register",
    "metering_points.meter_change.close": "Close",
    "metering_points.meter_change.tooltip": "Register new meter change",
    "metering_points.annual_consumption_energy": "Ref. tot. energy [MWh]", //Contains no-break spaces
    "metering_points.annual_consumption_flow": "Ref. tot. volume [m³]", //Contains no-break spaces
    "metering_points.update": "Update metering point list",
    "metering_points.missing_reference_period": "[reference period missing]",
    "metering_point_information.add_meter_change_missing_info": "More meter change information required",
    "metering_point_information.error_message_add_meter_change": "Could not register meter change",
    "metering_point_information.error_message_update_reference_period": "Could not update reference period",
    "metering_point.ref_preview.title": "Preview the reference period",
    "metering_point.ref_preview.number_of_errors.energy": "Alarms energy",
    "metering_point.ref_preview.number_of_errors.flow": "Alarms volume",
    "metering_point.ref_preview.difference.energy": "Difference [MWh]",
    "metering_point.ref_preview.difference.flow": "Difference [m³]",
    "metering_point.ref_preview.abs_difference.energy": "Abs. differences [MWh]",
    "metering_point.ref_preview.abs_difference.flow": "Abs. differences [m³]",
    "metering_point.ref_preview.number_of_days.energy": "Number of days energy",
    "metering_point.ref_preview.number_of_days.flow": "Number of days volume",
    "metering_point.ref_preview.tot_days": "Tot days",
    "metering_point.ref_preview.tooltips": "Preview new reference period",
    "metering_point.ref_preview.preview": "Preview",
    "metering_point.ref_preview.diagram.energy_legend": "Energy",
    "metering_point.ref_preview.diagram.missing_legend": "Missing",
    "metering_point.ref_preview.save": "Save",
    "metering_point.ref_preview.close": "Close",

    //-------------------------- CIT -> Protocol Panel --------------------------------------
    "metering_point.protocol_panel.title": "Error codes report",
    "metering_point.protocol_panel.main_cols.protocol_id": "Protocol Id",
    "metering_point.protocol_panel.main_cols.creator": "Creator",
    "metering_point.protocol_panel.main_cols.date": "Date",
    "metering_point.protocol_panel.main_cols.origin": "Origin",
    "metering_point.protocol_panel.main_cols.job_id": "Job Id",
    "metering_point.protocol_panel.main_cols.view": "View",
    "metering_point.protocol_panel.sub_cols.deviation_id": "Deviation Id",
    "metering_point.protocol_panel.sub_cols.cause": "Cause",
    "metering_point.protocol_panel.sub_cols.component": "Component",
    "metering_point.protocol_panel.sub_cols.fault_description": "Fault Description",
    "metering_point.protocol_panel.sub_cols.energy_usage_change": "Energy Usage Change",
    "metering_point.protocol_panel.sub_cols.action": "Action",
    "metering_point.protocol_panel.sub_cols.status": "Status",

    //-------------------------- CIT -> Create Protocol Form --------------------------------------
    "metering_point.create_protocol.tooltips": "Create Protocol",
    "metering_point.create_protocol.create": "Create Protocol",
    "metering_point.create_protocol.create_deviation": "Add",
    "metering_point.create_protocol.delete_deviation": "Remove",
    "metering_point.create_protocol.close": "Close",
    "metering_point.create_protocol_job_id.label": "Job ID",
    "metering_point.create_protocol_ticket_id.label": "Ticket Id",
    "metering_point.create_protocol_meetering_id.label": "Metering ID",
    "metering_point.create_protocol_deviation_cause.label": "Deviation Cause",
    "metering_point.create_protocol_action.label": "Action",
    "metering_point.create_protocol_action_description.label": "Action Free Text",
    "metering_point.create_protocol_component.label": "Component",
    "metering_point.create_protocol_component_description.label": "Component Free Text",
    "metering_point.create_protocol_status.label": "Status",
    "metering_point.create_protocol_full_description.label": "Fault Description",
    "metering_point.create_protocol_full_description.create_date": "Date",

    //create_protocol_deviation_cause
    "metering_point.create_protocol_deviation_cause_FAULT_PRIM_DISTR_HEAT": "Fault in Primary System District Heating",
    "metering_point.create_protocol_deviation_cause_FAULT_DISTR_HEAT_MTR": "Fault in District Heating Metering",
    "metering_point.create_protocol_deviation_cause_FAULT_HEAT_SYST": "Fault in Heating System",
    "metering_point.create_protocol_deviation_cause_FAULT_DOM_HOT_WTR_SYST": "Fault Domestic Hot Water system",
    "metering_point.create_protocol_deviation_cause_FAULT_DISTR_SYST": "Fault in Distribution System",
    "metering_point.create_protocol_deviation_cause_ENERGY_USAGE_CHANGE": "Changed Energy Use",
    //create_protocol_action
    "metering_point.create_protocol_action_ACTION_CHANGED_UNIT": "Changed unit",
    "metering_point.create_protocol_action_ACTION_REPAIRED": "Repaired",
    "metering_point.create_protocol_action_ACTION_MAINTENANCE_PLANNED": "Maintenance planned",
    "metering_point.create_protocol_action_ACTION_SETTING_CHANGED": "Setting changed",
    "metering_point.create_protocol_action_ACTION_OTHER": "Other - text",
    //create_protocol_status
    "metering_point.create_protocol_STATUS_HANDLED": "Handled",
    "metering_point.create_protocol_STATUS_PARTLY_HANDLED": "Partly handled",
    "metering_point.create_protocol_STATUS_NOT_HANDLED": "Not handled",
    //create_protocol_COMP
    "metering_point.create_protocol_COMP_ACT": "Actuator",
    "metering_point.create_protocol_COMP_AIR_BLEED_VLV": "Air bleed valve",
    "metering_point.create_protocol_COMP_BAL_VLV": "Balancing_valve",
    "metering_point.create_protocol_COMP_BYPASS_VLV": "Bypass valve",
    "metering_point.create_protocol_COMP_CHECK_VLV": "Check valve",
    "metering_point.create_protocol_COMP_CIRC_PUMP": "Circulation pump",
    "metering_point.create_protocol_COMP_COMM_UNIT": "Communication unit",
    "metering_point.create_protocol_COMP_COM_FAULTY": "Faulty communication",
    "metering_point.create_protocol_COMP_CONTROLLER": "Controller/Control",
    "metering_point.create_protocol_COMP_CONTR_VLV": "Control valve",
    "metering_point.create_protocol_COMP_DIFF_PRESS": "Differential pressure",
    "metering_point.create_protocol_COMP_DRAIN_VLV": "Drain Valve",
    "metering_point.create_protocol_COMP_EXP_VESSEL": "Expansion vessel",
    "metering_point.create_protocol_COMP_FAN_COIL_UNIT": "Fan coil unit",
    "metering_point.create_protocol_COMP_FILL_VLV_CHK_VLV": "Filling valve with check valve",
    "metering_point.create_protocol_COMP_FILTER": "Filter",
    "metering_point.create_protocol_COMP_FLOW_SENS": "Flow sensor",
    "metering_point.create_protocol_COMP_FLUSH": "Flushing valve",
    "metering_point.create_protocol_COMP_FUSE_ELECTRICITY_SUPPLY": "Fuse electricity supply",
    "metering_point.create_protocol_COMP_HEAT_EXCH": "Heat exchanger",
    "metering_point.create_protocol_COMP_INDR_TEMP_SENS": "Temperature sensor, indoor",
    "metering_point.create_protocol_COMP_INTEGR": "Integrator",
    "metering_point.create_protocol_COMP_MANOM": "Manometer",
    "metering_point.create_protocol_COMP_MEETER_READING": "Meter reading system",
    "metering_point.create_protocol_COMP_MIX_VLV": "Mixing_valve",
    "metering_point.create_protocol_COMP_MIX_VLV_TEMP_SENS": "Temperature_sensor,mixing_valve",
    "metering_point.create_protocol_COMP_NONE": '"none"',
    "metering_point.create_protocol_COMP_OTHER": "Other - text",
    "metering_point.create_protocol_COMP_OTHER_DIST_EQUIP": "Other disturbing equipment",
    "metering_point.create_protocol_COMP_OUTD_TEMP_SENS": "Temperature sensor, outdoor",
    "metering_point.create_protocol_COMP_PIPE": "Pipe",
    "metering_point.create_protocol_COMP_RADI": "Radiator",
    "metering_point.create_protocol_COMP_RET_TEMP_SENS": "Temperature sensor, return",
    "metering_point.create_protocol_COMP_SAFETY_VLV": "Safety valve",
    "metering_point.create_protocol_COMP_SEC_RET_TEMP_SENS": "Temperature_sensor,secondary return",
    "metering_point.create_protocol_COMP_SEC_SUPPLY_TEMP_SENS": "Temperature_sensor,secondary supply",
    "metering_point.create_protocol_COMP_SERV_CONN_ISOL_VLV": "Service connection isolating valve",
    "metering_point.create_protocol_COMP_SHUNT_GRP": "Shunt group",
    "metering_point.create_protocol_COMP_SHUTOFF_VLV": "Shut-off valve",
    "metering_point.create_protocol_COMP_SUPPLY_TEMP_SENS": "Temperature sensor, supply",
    "metering_point.create_protocol_COMP_TAP_MIX_MIX_VLV": "Tap_water_mixer_with_mixing_valve",
    "metering_point.create_protocol_COMP_THERM_RAD_VLV": "Thermostatic radiator valve",
    "metering_point.create_protocol_COMP_THROT": "Throttle valve",
    "metering_point.create_protocol_COMP_UNDERFLOOR_HEAT": "Underfloor heating",
    "metering_point.create_protocol_COMP_VALIDATION": "Validation system",
    "metering_point.create_protocol_COMP_VENTIL_UNIT": "Ventilation unit",
    // energy_usage_change
    "metering_point.create_protocol_energy_usage_change_CHANGE_BUSINESS": "Altered business in installation",
    "metering_point.create_protocol_energy_usage_change_CHANGE_COMPL_SUPPL": "Altered complementary heating supply",
    "metering_point.create_protocol_energy_usage_change_CHANGE_FIXED_FAULT":
      "Fixed fault gives different heat consumption pattern",
    "metering_point.create_protocol_energy_usage_change_CHANGE_HEAT_NEEDS": "Altered heating needs",
    "metering_point.create_protocol_energy_usage_change_CHANGE_INTENT_SHUTOFF": "Intentional shut-off",
    "metering_point.create_protocol_energy_usage_change_CHANGE_NONE": '"none"',
    "metering_point.create_protocol_energy_usage_change_CHANGE_NRG_EFF_MEAS": "Energy efficiency measures",
    "metering_point.create_protocol_energy_usage_change_CHANGE_OTHER": "Other - text",
    "metering_point.create_protocol_energy_usage_change_CHANGE_UNINT_SHUTOFF": "Unintentional shut-off",
    "metering_point.create_protocol_energy_usage_change.label": "Chanage av energianv usage",
    "metering_point.create_protocol_mandatory": "mandatory",

    // label
    "metering_point.label_panel.title": "Labels",
    // create label popup
    "metering_point.create_label.title": "Add to the labels here",
    "metering_point.create_label.create": "Create",
    "metering_point.create_label.description": "Description",
    "metering_point.create_label.description_placeholder": "Enter the label description here!",
    "metering_point.create_label.error_message": "Please select the data points on the graph before creating label.",
    "metering_point.create_label.info": "Info: xx points selected",
    // metering point protocols upload
    "metering_point.protocol_upload": "Upload protocols from ex. excel file",
    "metering_point.protocol_upload_choose_file": "Choose File",
    "metering_point.protocol_upload_file": "Upload",
    "metering_point.protocol_upload_contains_error": "Contains Error",
    "metering_point.protocol_upload_success":
      "Protocols are added, close the dialog and view the newly added protocol.",
    "metering_points.static_ref_period": "Static ref. period", 
    "metering_points.remove_ref_period": 
      "Are you sure you want to deactivate manual reference period and revalidate with Arnold?",
    "metering_points.revalidating": "Revalidating...",  
    // create invalid data popup
    "metering_point.invalid_data_popup_title": "Mark selected points as valid/invalid data.",
    "metering_point.invalid_data_popup_valid": "Valid",
    "metering_point.invalid_data_popup_invalid": "Invalid",

    "upload_deviations.upload_button_title": "Upload protocols",

    //----------------------------------------------------------------
    "metering_point.ref_preview.diagram.title": "Reference period",
    "metering_point.ref_preview.save": "Save",
    "metering_point.ref_preview.close": "Close",
    "metering_point.ref_preview.confirm": "Confirm new reference period",
    "metering_point.ref_preview.cancel": "Reset reference period",
    "metering_point.ref_preview.stats": "Show statistics for new reference period",
    "metering_point.ref_preview.show_preview": "Preview new reference period",
    "chart.diagrams": "Diagrams",
    "chart.change_end_date": "Change end date",
    "chart.energy_diagram": "Energy",
    "chart.flow_diagram": "Volume",
    "chart.delta_T_diagram": "Temperatures",
    "chart.from_date": "Viewed period, from",
    "chart.to_date": "Viewed period, to",
    "chart.energy_per_day": "Energy consumption per day [MWh]",
    "chart.energy_daily_average": "Power need daily average [kW]",
    "chart.flow_per_day": "Volume per day [m³]",
    "chart.flow_daily_average": "Volume per hour, daily average [m³/h]",
    "chart.temperature_outdoor_daily_average": "Outdoor temperature [°C]",
    "chart.group": "Group",
    "chart.group.measured": "measured",
    "chart.group.calculated": "calculated",
    "chart.group.invalid_data": "invalid data",
    "chart.group.label": "label",
    "chart.temp": "Temp",
    "chart.marked": "Marked",
    "chart.marked_period_length": "Marking, length",
    "chart.marked_period_start_date": "Marking, from",
    "chart.marked_period_end_date": "Marking, to",
    "chart.marked_period_step_forward": "Step marked period forward",
    "chart.marked_period_step_back": "Step marked period back",
    "chart.entire_period": "Entire period",
    "chart.no_marking": "No marking",
    "chart.period_freeze": "Viewed period locked",
    "chart.period_freeze_short": "Lock",
    "chart.edit_bad_data": "Add/remove bad data",
    "chart.show_bad_data": "Show bad data",
    "chart.show_bad_data_short": "Show",
    "chart.toggle_chart_hover_label": "Show tooltip in the charts when hovering over a point",
    "chart.toggle_chart_hover_label_short": "Tooltip",
    "chart.toggle_energy_chart_unit": "Use kW as the unit in the energy charts",
    "chart.toggle_energy_chart_unit_short": "kW",
    "chart.toggle_flow_chart_unit": "Use m³/h as the unit in the volume charts",
    "chart.toggle_flow_chart_unit_short": "m³/h",
    "chart.label.energy": "Energy",
    "chart.label.power": "Avg power",
    "chart.label.volume": "Volume",
    "chart.label.flow": "Avg volume",
    "chart.month_names": "Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec",
    "chart.zoom": "Zoom",
    "chart.loading": "Loading diagram data...",
    "chart.flow_temperature": "Flow temp",
    "chart.return_temperature": "Return temp",
    "chart.temperature_difference": "Delta T",
    "chart.outdoor_temperature": "Outdoor temp",
    "chart.temperature.y": "Temperature [°C]",
    "chart.theme": "Chart color theme",
    "chart.theme.default": "Default",
    "chart.theme.classic": "Classic",
    "chart.alarms": "Alarmed",
    "chart.swap_date": "Swap ref/view period",
    "chart.validate": "Revalidate metering point",
    "chart.validate_from_date": "Validate from",
    "chart.validate_to_date": "Validate to",
    "chart.validate_cancel": "Cancel",
    "chart.validate_submit": "Validate",
    "chart.chart_linking": "Chart linking",
    "chart.chart_linking.disabled": "Disabled",
    "chart.chart_linking.enabled": "Enabled",
    "chart.widths": "Chart width ratio",
    "chart.height": "Chart height",
    "chart.separate_calculated": "Separate calculated values",
    "chart.separate_calculated_short": "Split",
    "chart.symbol": "Chart symbol",
    "chart.symbol.circle": "Circle",
    "chart.symbol.square": "Square",
    "chart.symbol.diamond": "Diamond",
    "chart.symbol.cross": "Cross",
    "chart.symbol.x": "X",
    "chart.symbol.triangle": "Triangle",
    "chart.symbol.size": "Symbol size",
    "chart.theme.tooltip.text": "Chart color theme: Denotes the color theme of the charts.",
    "chart.chart_linking.tooltip.text":
      "Chart linking: Determines whether interaction with one chart is reflected in the other charts.",
    "chart.widths.tooltip.text":
      "Chart width ratio: Denotes the ratio between the width of the signature and time charts. For example, 5:7 means the signature chart uses 5/12 of the width while the time chart uses 7/12.",
    "chart.height.tooltip.text": "Chart height: Determines how tall the charts are (in pixels).",
    "chart.symbol.tooltip.text": "Chart symbol: Denotes the shape of the points in the scatter charts.",
    "chart.symbol.size.tooltip.text": "Symbol size: Denotes the size of the points in the scatter charts.",
    "chart.missing_data": "Missing", 
    "chart.not_validated_data": "Not validated",
    "chart.lastestValidated": "Latest validated validerad",
    "chart.not_all_data_validated": "Not all data is validated",
    "configuration.charts": "Charts",
    "diffcalculation.referenceFrom": "Reference period, from", //Contains no-break spaces
    "diffcalculation.referenceTo": "Reference period, to", //Contains no-break spaces
    "diffcalculation.appliedFrom": "Apply from",
    "diffcalculation.appliedTo": "Apply to",
    "diffcalculation.calculate": "Calculate",
    "diffcalculation.measured_consumption": "Measured consumption",
    "diffcalculation.calculated_consumption": "Calculated consumption",
    "diffcalculation.calculated_consumption_with_missing": "Calculated consumption (of which missing measures)",
    "diffcalculation.difference": "Difference",
    "diffcalculation.result": "Result",
    "diffcalculation.error_message": "Error in recalculation",
    "diffcalculation.error_message_no_readings": "No meter readings were found in the specified reference period for ",
    "diffcalculation.missing_weather_data": "Weather data missing for the following days:",
    smart_energi_datalake_export: "Smart Energi shared datalake export",
    "smart_energi_datalake_export.enable": "Enable export",
    "smart_energi_datalake_export.disable": "Disable export",
    "smart_energi_datalake_export.select_tag_value_placeholder": "Select at least one value..",
    "smart_energi_datalake_export.excluded_metering_point_fields": "Excluded metering point fields",
    "smart_energi_datalake_export.select_excluded_metering_point_fields_placeholder":
      "Select the metering point fields to exclude in exports..",
    "smart_energi_datalake_export_preview.header": "Smart Energi shared datalake preview",
    "smart_energi_datalake_export_preview.header.info": "Info",
    "smart_energi_datalake_export_preview.total": "Total: \t",
    "smart_energi_datalake_export_preview.exported": "Exported: \t",
    "smart_energi_datalake_export_preview.reduced": "Reduced: \t",
    "smart_energi_datalake_export_preview.excluded": "Excluded: \t",
    "smart_energi_datalake_export_preview.metering_point_id": "Metering point",
    "smart_energi_datalake_export_preview.header.per_tag": "Per tag",
    "smart_energi_datalake_export_preview.preview": "Preview",
    "metering_point.metering_point_id": "Metering point ID",
    "metering_point.address": "Address",
    "metering_point.ref_period": "Reference period",
    "metering_point.tags": "Tags",
    "metering_point.labels": "Labels",
    "metering_point.notes": "Notes",
    "metering_point.deviations": "Deviations",
    "metering_point.filter_table_toggle_tooltip": "Filter tags based on AND/OR",
    "metering_point.filter_table_toggle_heading": "Filter tags",
    "MUIDataTables.textLabels": {
      body: {
        noMatch: "No data to show yet",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    // cooling
    "cooling.overflow": "Overflow",
    "cooling.delta_t": "Flow-weighted Delta-T",
    "cooling.momentan_delta_t": "Avg inst Delta-T",
    "cooling.flow_temperature": "Flow temp",
    "cooling.return_temperature": "Return temp",
    "cooling.sort": "Sort",
    "cooling.loading": "Loading...",
    "cooling.rows_per_page": "Rows per page",
    "cooling.of": "of",
    "cooling.filter_metering_point": "Filter by metering points/address",
    "cooling.download_csv": "Download CSV",
    "cooling.calculated_at": "Calculated at",

    // status
    "status.reading_1_day": "1 Day",
    "status.reading_3_days": "3 Days",
    "status.reading_7_days": "7 Days",
    "status.error_list": "Measures with error status",
    "status.error_list_missing_measures": "Metering points with missing meter readings",
    "status.reading_status": "Reading status",
    "status.distribution": "Distribution of measurement errors",
    "status.errors_per_day": "Number of errors per day",
    "status.loaded_readings": "meter value loaded",
    "status.error_code": "Error code",
    "status.timestamp": "Timestamp",
    "status.day": "Day",
    "status.address": "Address",
    "status.metering_point_id": "Metering point-ID",
    "status.description": "Description",
    "status.no_error": "No error",
    "status.no_measures": "Missing meter readings",
    "status.total_number_of_metering_point_without_values":
      "The number of metering points with no measurement values per day",
    "status.no_address": "No address found",
    "status.no_errors_reported": "No errors have been reported",
    "status.no_missing_meter_readings": "No meter readings are missing",
    "status.loading": "Loading",
    "status.failed_to_fetch_status": "Can't fetch status from server",
    //Error codes from meter_readings
    "status.validate_simple_error_negative_value": "Negative consumption value imported",
    "status.validate_simple_error_in_future": "Consumption value from a date in future",
    "status.validate_simple_error_false_positive": "False positive consumption",
    "status.calculate_consuming_values_error": "Missing meter reading",
    //Error codes from regression
    "status.validate_advanced_error_no_weather_data": "No weather data could be found",
    "status.validate_advanced_error_no_metering_point_found": "The metering point is missing",
    "status.validate_advanced_error_no_weather_station_info": "No weather station found",
    "status.validate_advanced_error_unknown": "Error unknown",
    "status.validate_advanced_error_no_reference_period": "No reference-period found",
    "status.validate_advanced_error_no_reference_readings": "Reference readings are missing",
    "status.validate_advanced_error_too_few_reference_values": "Too few reference values found",
    "status.not_validated": "Not validated",
  },
  sv: {
    energy: "Energi",
    flow: "Volym",
    aboutK2: "Om K2",
    account: "Konto",
    language: "Språk",
    login: "Logga in",
    logout: "Logga ut",
    manage_users: "Hantera användare",
    dashboard: "Dashboard",
    alarms: "Larm",
    metering_points: "Mätpunkter",
    metering_point: "Mätpunkt",
    diffcalculation: "Efterberäkning",
    cooling: "Avkylning",
    configuration: "Inställningar",
    day: "dag",
    week: "vecka",
    weeks: "veckor",
    month: "månad",
    months: "månader",
    year: "år",
    invalid_date_message: "Ogiltigt datumformat",
    max_date_message: "Var god välj ett tidigare datum",
    min_date_message: "Var god välj ett senare datum",
    add: "Lägg till",
    apply: "Uppdatera",
    cancel: "Avbryt",
    delete: "Ta bort",
    ok: "Ok",
    save: "Spara",
    search: "Sök",
    update: "Uppdatera",
    default: "Standard",
    tags: "Taggar",
    "tags.select": "Välj...",
    "tags.add_new": "Lägg till ny tagg",
    "tags.select_or_create": "Välj eller lägg till ny...",
    "alarm.number_of_alarms": "Antal larm",
    "alarm.accumulated_deviation": "Ackumulerad avvikelse",
    "alarm.normalized_accumulated_deviation": "Normaliserad ackumulerad avvikelse",
    "alarm.metering_point_status": "Mätpunktens status",
    "alarm.newest_note": "Senaste kommentar",
    "alarm.delta_t": "Flödesviktat Delta-T",
    "alarm.overflow": "Överflöde",
    "alarm.return_temperature": "Returtemp",
    "alarm.flow_temperature": "Framtemp",
    "alarm.meter_reading_property": "Egenskap",
    "alarm.property_energy": "Energi",
    "alarm.property_flow": "Volym",
    "alarm.sort": "Sortera",
    "alarm.sort.accumulated_deviation":
      "Sortera (Ackumulerad avvikelse är summan av absolutbeloppen av de larmade avvikelserna under den valda perioden.)",
    "alarm.sort.accumulated_opposite":
      "Sortera (Viktad ackumulerad avvikelse är summan av de larmade avvikelserna (med tecken) under den valda perioden.)",
    "alarm.sort.normalized_accumulated_deviation":
      "Sortera (Normaliserad ackumulerad avvikelse innebär att avvikelsen viktas mot mätpunktens totala förbrukning under den valda perioden.) ",
    "alarm.rows_per_page": "Rader per sida",
    "alarm.of": "av",
    "alarm.download_csv": "Ladda ned CSV",
    "alarm.filter_table": "Filtrera tabell",
    "alarm.filter_table_toggle_tooltip": "Filtrera taggarna baserat på AND eller OR mellan dem",
    "alarm.deviation": "Avvikelse",
    "alarm.status": "Status",
    "alarm.all": "Alla",
    "alarm.all_description": "Visar summan av alla avvikelser under perioden",
    "alarm.higher": "Högre",
    "alarm.higher_description": "Visar summan av alla avvikelser där uppmätt värde är högre än beräknat värde",
    "alarm.lower": "Lägre",
    "alarm.lower_description": "Visar summan av alla avvikelser där uppmätt värde är mindre än beräknat värde",
    "alarm.none": "<Ingen>",
    "alarm.loading": "Laddar larm...",
    "alarm.filter_metering_point": "Filtrera på mätpunkter/adress",
    "alarm.error_message_fetching_data": "Fel vid hämtning av data",
    "alarm.accumulated_opposite": "Viktad ackumulerad",
    "alarm.last_updated": "Senast uppdaterat: ",
    "alarm.update": "Hämta om larm",
    "alarm.rebuild": "Skapa nya larmlistor (i bakgrunden)",
    "alarm.rebuilding": "Nya larmlistor skapas. Du kan fortsätta arbeta under tiden.",
    "alarm.rebuild_done": "Nya larmlistor har skapats.",
    "metering_points.not_found": "Det finns ingen mätpunkt med namnet ",
    "metering_points.fetch_error": "Någonting gick fel i försöket att hämta mätpunkt ",
    "metering_points.note": "Anteckning",
    "metering_points.note.user": "Användare",
    "metering_points.note.timestamp": "Tid",
    "metering_points.add_note_button": "Lägg till",
    "metering_points.write_note_here": "Skriv din anteckning här",
    "metering_points.show_notes": "Visa anteckningar",
    "metering_points.show_labels": "Visa etiketter",
    "metering_points.add_new_label": "Lägg till ny etikett",
    "metering_points.labels_id": "id",
    "metering_points.labels_description": "beskrivning",
    "metering_points.labels_tags": "tag",
    "metering_points.labels_creator": "skapare",
    "metering_points.labels_create_date": "skapa datum",
    "metering_points.altitude": "Höjd över havet",
    "metering_points.latitude": "Latitud",
    "metering_points.type": "Typ",
    "metering_points.ref_period_from": "Referensperiod, fr.o.m.", //Contains a no-break space
    "metering_points.ref_period_to": "Referensperiod, t.o.m.", //Contains a no-break space
    "metering_points.longitude": "Longitud",
    "metering_points.address": "Adress",
    "metering_points.manufacturer": "Tillverkare",
    "metering_points.meter_id": "MätarID",
    "metering_points.model": "Modell",
    "metering_points.reading_frequency": "Avläsningsintervall (s)", //Contains a no-break space
    "metering_points.other": "Annat",
    "metering_points.status": "Status",
    "metering_points.metering_point": "Mätpunkt",
    "metering_points.metering_points": "Mätpunkter",
    "metering_points.tag_key": "Nyckel",
    "metering_points.tag_val": "Värde",
    "metering_points.street": "Gata",
    "metering_points.postal_code": "Postnummer",
    "metering_points.city": "Stad",
    "metering_points.weather_station_id": "Väderstation",
    "metering_points.weather_station_agency": "Utgivare, väderstation",
    "metering_points.download_csv": "Ladda ned CSV",
    "metering_points.download_csv_from_date": "Datum, fr.o.m.",
    "metering_points.download_csv_to_date": "Datum, t.o.m.",
    "metering_points.download_show_data": "Visa data",
    "metering_points.export.aggregated_data": "Aggregerad data",
    "metering_points.export.metering_point_id": "Mätpunkts-ID",
    "metering_points.export.property": "Typ",
    "metering_points.export.timestamp": "Tidpunkt",
    "metering_points.export.value": "Värde",
    "metering_points.export.unit_of_measure": "Mätenhet",
    "metering_points.export.reading_status": "Lässtatus",
    "metering_points.export.consumption_from": "Förbrukning från",
    "metering_points.export.consumption_to": "Förbrukning till",
    "metering_points.export.consumption_value": "Förbrukning, värde",
    "metering_points.export.calculated_value": "Beräknat värde",
    "metering_points.export.calculated_normalized_value": "Beräknat normaliserat värde",
    "metering_points.export.calculated_deviation_type": "Beräknad avvikelse, typ",
    "metering_points.export.calculated_deviation_level": "Beräknad avvikelse, nivå",
    "metering_points.export.temperature": "Utetemperatur",
    "metering_points.export.energy": "Förbrukning, energi (MWh)",
    "metering_points.export.ref_energy": "Ref, energi",
    "metering_points.export.energy_value": "Energiställning",
    "metering_points.export.energy_status": "Energistatus",
    "metering_points.export.flow": "Förbrukning, volym (m³)",
    "metering_points.export.ref_flow": "Ref, volym",
    "metering_points.export.flow_value": "Volymställning",
    "metering_points.export.flow_status": "Volymstatus",
    "metering_points.export.measured": "Uppmätt",
    "metering_points.export.calculated": "Beräknad",
    "metering_points.status_under_surveillance": "Under bevakning",
    "metering_points.status_under_investigation": "Under utredning",
    "metering_points.status_investigated": "Utredd",
    "metering_points.status_forget": "Glömd",
    "metering_points.status_ok": "Ok",
    "metering_points.changed_status": "Status ändrad till",
    "metering_points.status_": "Nollställ status",
    "metering_points.status_none": "<Ingen>",
    "metering_points.meter_change.add": "Registrera nytt mätarbyte",
    "metering_points.meter_change_type": "Mätarbyte, typ",
    "metering_points.meter_change_date": "Senaste mätarbyte",
    "metering_points.meter_change_serial_number": "Mätarbyte, serienummer", //Contains no-break spaces
    "metering_points.meter_change_comment": "Mätarbyte, kommentar", //Contains no-break spaces
    "metering_points.meter_change_type_add": "Mätarbyte, typ",
    "metering_points.meter_change_date_add": "Senaste mätarbyte",
    "metering_points.meter_change_serial_number_add": "Mätarbyte, serienummer", //Contains no-break spaces
    "metering_points.meter_change_comment_add": "Mätarbyte, kommentar", //Contains no-break spaces
    "metering_points.meter_change.addButton": "Registrera",
    "metering_points.meter_change.close": "Stäng",
    "metering_points.meter_change.tooltip": "Registrera nytt mätarbyte",
    "metering_points.annual_consumption_energy": "Ref. tot. energi [MWh]", //Contains no-break spaces
    "metering_points.annual_consumption_flow": "Ref. tot. volym [m³]", //Contains no-break spaces
    "metering_points.update": "Uppdatera mätpunktslista",
    "metering_points.missing_reference_period": "[referensperiod saknas]",
    "metering_point_information.add_meter_change_missing_info": "Mer information om mätarbytet behövs",
    "metering_point_information.error_message_add_meter_change": "Kunde inte registrera mätarbyte",
    "metering_point_information.error_message_update_reference_period": "Kunde inte uppdatera referensperiod",
    "metering_point.ref_preview.title": "Förhandsgranska referensperioden",
    "metering_point.ref_preview.number_of_errors.energy": "Larm energi",
    "metering_point.ref_preview.number_of_errors.flow": "Larm volym",
    "metering_point.ref_preview.difference.energy": "Differens [MWh]",
    "metering_point.ref_preview.difference.flow": "Differens [m³]",
    "metering_point.ref_preview.abs_difference.energy": "Abs. differens [MWh]",
    "metering_point.ref_preview.abs_difference.flow": "Abs. differens [m³]",
    "metering_point.ref_preview.tooltips": "Förhandsvisning av referensperioden",
    "metering_point.ref_preview.number_of_days.energy": "Antal dagar energi",
    "metering_point.ref_preview.number_of_days.flow": "Antal dagar volym",
    "metering_point.ref_preview.tot_days": "Antal dagar",
    "metering_point.ref_preview.save": "Spara",
    "metering_point.ref_preview.close": "Stäng",
    "metering_point.ref_preview.preview": "Förhandsvisning",
    "metering_point.ref_preview.diagram.energy_legend": "Energi",
    "metering_point.ref_preview.diagram.missing_legend": "Saknar data",

    //-------------------------- CIT -> Protocol Panel --------------------------------------
    "metering_point.protocol_panel.title": "Felkodrapport",
    "metering_point.protocol_panel.main_cols.protocol_id": "Protokoll-ID",
    "metering_point.protocol_panel.main_cols.creator": "Skapare",
    "metering_point.protocol_panel.main_cols.date": "Datum",
    "metering_point.protocol_panel.main_cols.origin": "Ursprung",
    "metering_point.protocol_panel.main_cols.job_id": "Jobb-ID",
    "metering_point.protocol_panel.main_cols.view": "Visa",
    "metering_point.protocol_panel.sub_cols.deviation_id": "Avvikelse-ID",
    "metering_point.protocol_panel.sub_cols.cause": "Orsak",
    "metering_point.protocol_panel.sub_cols.component": "Komponent",
    "metering_point.protocol_panel.sub_cols.fault_description": "Beskrivning av fel",
    "metering_point.protocol_panel.sub_cols.energy_usage_change": "Förändring av energianvändning",
    "metering_point.protocol_panel.sub_cols.action": "Handling",
    "metering_point.protocol_panel.sub_cols.status": "Status",

    //-------------------------- CIT -> Create Protocol Form --------------------------------------
    "metering_point.create_protocol.tooltips": "Skapa Protokoll",
    "metering_point.create_protocol.create": "Skapa Protokoll",
    "metering_point.create_protocol.create_deviation": "Lägg till",
    "metering_point.create_protocol.delete_deviation": "Ta bort",
    "metering_point.create_protocol.close": "Stäng",
    "metering_point.create_protocol_job_id.label": "Jobb-ID",
    "metering_point.create_protocol_ticket_id.label": "Ärende-ID",
    "metering_point.create_protocol_meetering_id.label": "Mätnings-ID",
    "metering_point.create_protocol_deviation_cause.label": "Avvikelse, Orsak",
    "metering_point.create_protocol_action.label": "Åtgärd",
    "metering_point.create_protocol_action_description.label": "Åtgärd, Beskrivning",
    "metering_point.create_protocol_component.label": "Komponent",
    "metering_point.create_protocol_component_description.label": "Komponent, Beskrivning",
    "metering_point.create_protocol_status.label": "Status",
    "metering_point.create_protocol_full_description.label": "Felbeskrivning",
    "metering_point.create_protocol_full_description.create_date": "Datum",

    //create_protocol_deviation_cause
    "metering_point.create_protocol_deviation_cause_FAULT_PRIM_DISTR_HEAT":
      "Fel i Prim\u00e4rkrets fj\u00e4rrv\u00e4rme",
    "metering_point.create_protocol_deviation_cause_FAULT_DISTR_HEAT_MTR": "Fel i Fj\u00e4rrv\u00e4rmem\u00e4tning",
    "metering_point.create_protocol_deviation_cause_FAULT_HEAT_SYST": "Fel i Uppv\u00e4rmningskrets",
    "metering_point.create_protocol_deviation_cause_FAULT_DOM_HOT_WTR_SYST": "Fel i Tappvarmvattenkrets",
    "metering_point.create_protocol_deviation_cause_FAULT_DISTR_SYST": "Fel i Distributionsn\u00e4t",
    "metering_point.create_protocol_deviation_cause_ENERGY_USAGE_CHANGE": "F\u00f6r\u00e4ndrad energianv\u00e4ndning",
    //create_protocol_action
    "metering_point.create_protocol_action_ACTION_CHANGED_UNIT": "Utbytt enhet",
    "metering_point.create_protocol_action_ACTION_REPAIRED": "Reparerad",
    "metering_point.create_protocol_action_ACTION_MAINTENANCE_PLANNED": "Planerat underh\u00e5ll",
    "metering_point.create_protocol_action_ACTION_SETTING_CHANGED": "\u00c4ndrad inst\u00e4llning",
    "metering_point.create_protocol_action_ACTION_OTHER": "Annat - fritext",
    //create_protocol_status
    "metering_point.create_protocol_STATUS_HANDLED": "\u00c5tg\u00e4rdat",
    "metering_point.create_protocol_STATUS_PARTLY_HANDLED": "Delvis \u00e5tg\u00e4rdat",
    "metering_point.create_protocol_STATUS_NOT_HANDLED": "Ej \u00e5tg\u00e4rdat",
    //create_protocol_COMP
    "metering_point.create_protocol_COMP_ACT": "St\u00e4llmotor/st\u00e4lldon",
    "metering_point.create_protocol_COMP_AIR_BLEED_VLV": "Luftningsventil",
    "metering_point.create_protocol_COMP_BAL_VLV": "Injusteringsventil/Strypventil",
    "metering_point.create_protocol_COMP_BYPASS_VLV": "Varmh\u00e5llning servis/rundg\u00e5ng",
    "metering_point.create_protocol_COMP_CHECK_VLV": "Backventil",
    "metering_point.create_protocol_COMP_CIRC_PUMP": "Cirkulationspump",
    "metering_point.create_protocol_COMP_COMM_UNIT": "Kommunikationsenhet",
    "metering_point.create_protocol_COMP_COM_FAULTY": "Bristf\u00e4llig kommunikation",
    "metering_point.create_protocol_COMP_CONTROLLER": "Reglercentral/reglering",
    "metering_point.create_protocol_COMP_CONTR_VLV": "Reglerventil/Styrventil/Prim\u00e4rventil/Regleringsventil",
    "metering_point.create_protocol_COMP_DIFF_PRESS": "Differenstryck",
    "metering_point.create_protocol_COMP_DRAIN_VLV": "T\u00f6mningsventil/Avtappningsventil",
    "metering_point.create_protocol_COMP_EXP_VESSEL": "Expansionsk\u00e4rl",
    "metering_point.create_protocol_COMP_FAN_COIL_UNIT": "Aerotemper",
    "metering_point.create_protocol_COMP_FILL_VLV_CHK_VLV": "P\u00e5fyllningsventil",
    "metering_point.create_protocol_COMP_FILTER": "Filter",
    "metering_point.create_protocol_COMP_FLOW_SENS": "Fl\u00f6desgivare",
    "metering_point.create_protocol_COMP_FLUSH": "Spolningsventil",
    "metering_point.create_protocol_COMP_FUSE_ELECTRICITY_SUPPLY": "S\u00e4kring elf\u00f6rs\u00f6rjning",
    "metering_point.create_protocol_COMP_HEAT_EXCH": "V\u00e4rmev\u00e4xlare",
    "metering_point.create_protocol_COMP_INDR_TEMP_SENS": "Temperaturgivare inomhus",
    "metering_point.create_protocol_COMP_INTEGR": "Integreringsverk",
    "metering_point.create_protocol_COMP_MANOM": "Manometerst\u00e4ll/manometer/tryckm\u00e4tare/filterkoppel",
    "metering_point.create_protocol_COMP_MEETER_READING": "M\u00e4tv\u00e4rdesinsamlingssystem",
    "metering_point.create_protocol_COMP_MIX_VLV": "Blandningsventil",
    "metering_point.create_protocol_COMP_MIX_VLV_TEMP_SENS": "Temperaturgivare blandningsventil",
    "metering_point.create_protocol_COMP_NONE": '"none"',
    "metering_point.create_protocol_COMP_OTHER": "Annat - fritext",
    "metering_point.create_protocol_COMP_OTHER_DIST_EQUIP": "Annan st\u00f6rande utrustning",
    "metering_point.create_protocol_COMP_OUTD_TEMP_SENS": "Temperaturgivare utomhus",
    "metering_point.create_protocol_COMP_PIPE": "Ledning/r\u00f6r",
    "metering_point.create_protocol_COMP_RADI": "Radiator",
    "metering_point.create_protocol_COMP_RET_TEMP_SENS": "Temperaturgivare retur",
    "metering_point.create_protocol_COMP_SAFETY_VLV": "S\u00e4kerhetsventil",
    "metering_point.create_protocol_COMP_SEC_RET_TEMP_SENS": "Temperaturgivare sekund\u00e4rt retur",
    "metering_point.create_protocol_COMP_SEC_SUPPLY_TEMP_SENS": "Temperaturgivare sekund\u00e4rt fram",
    "metering_point.create_protocol_COMP_SERV_CONN_ISOL_VLV": "Servisventil",
    "metering_point.create_protocol_COMP_SHUNT_GRP": "Shuntventil",
    "metering_point.create_protocol_COMP_SHUTOFF_VLV": "Avst\u00e4ngningsventil",
    "metering_point.create_protocol_COMP_SUPPLY_TEMP_SENS": "Temperaturgivare fram",
    "metering_point.create_protocol_COMP_TAP_MIX_MIX_VLV": "Blandare med blandningsventil",
    "metering_point.create_protocol_COMP_THERM_RAD_VLV": "Radiatorventil/Termostatventil",
    "metering_point.create_protocol_COMP_THROT": "Strypventil/effektbegr\u00e4nsningsventil",
    "metering_point.create_protocol_COMP_UNDERFLOOR_HEAT": "Golvv\u00e4rme",
    "metering_point.create_protocol_COMP_VALIDATION": "Valideringssystem",
    "metering_point.create_protocol_COMP_VENTIL_UNIT": "Ventilationsaggregat",
    // energy_usage_change
    "metering_point.create_protocol_energy_usage_change_CHANGE_BUSINESS":
      "F\u00f6r\u00e4ndrad verksamhet i fastigheten",
    "metering_point.create_protocol_energy_usage_change_CHANGE_COMPL_SUPPL":
      "F\u00f6r\u00e4ndrad kompletterande v\u00e4rmef\u00f6rs\u00f6rjning",
    "metering_point.create_protocol_energy_usage_change_CHANGE_FIXED_FAULT":
      "\u00c5tg\u00e4rdat fel ger avvikande f\u00f6rbrukningsm\u00f6nster",
    "metering_point.create_protocol_energy_usage_change_CHANGE_HEAT_NEEDS":
      "F\u00f6r\u00e4ndrat uppv\u00e4rmningsbehov",
    "metering_point.create_protocol_energy_usage_change_CHANGE_INTENT_SHUTOFF": "Avsiktlig avst\u00e4ngning",
    "metering_point.create_protocol_energy_usage_change_CHANGE_NONE": '"none"',
    "metering_point.create_protocol_energy_usage_change_CHANGE_NRG_EFF_MEAS":
      "Energibesparings\u00e5tg\u00e4rder/Energieffektivisering",
    "metering_point.create_protocol_energy_usage_change_CHANGE_OTHER": "Annat - fritext",
    "metering_point.create_protocol_energy_usage_change_CHANGE_UNINT_SHUTOFF": "Oavsiktlig avst\u00e4ngning",
    "metering_point.create_protocol_energy_usage_change.label": "förändring av energianvändningen",
    "metering_point.create_protocol_mandatory": "obligatorisk",

    "metering_point.ref_preview.diagram.title": "Referensperioden",
    "metering_point.ref_preview.confirm": "Bekräfta ny referensperiod",
    "metering_point.ref_preview.cancel": "Återställ referensperiod",
    "metering_point.ref_preview.stats": "Visa statistik om ny referensperiod",
    "metering_point.ref_preview.show_preview": "Förhandsvisa ny referensperiod",
    // label
    "metering_point.label_panel.title": "Etiketter",
    // create label popup
    "metering_point.create_label.title": "Lägg till din avvikelse här",
    "metering_point.create_label.create": "Skapa",
    "metering_point.create_label.description": "Beskrivning",
    "metering_point.create_label.description_placeholder": "Ange etikettbeskrivningen här!",
    "metering_point.create_label.error_message": "Välj datapunkter i diagrammet innan du skapar etikett.",
    "metering_point.create_label.info": "Info: xx mätvärden valda",

    //metering_point upload protocols
    "metering_point.protocol_upload": "Ladda upp protokoll från t.ex. en Excel-fil",
    "metering_point.protocol_upload_choose_file": "Välj fil",
    "metering_point.protocol_upload_file": "Ladda upp",
    "metering_point.protocol_upload_contains_error": "Innehåller fel",
    "metering_point.protocol_upload_success":
      "Protokoll läggs till; stäng dialogrutan och visa det nyligen tillagda protokollet.",
    "metering_points.static_ref_period": "Statisk ref. period", 
    "metering_points.remove_ref_period": 
      "Är du säker på att du vill avvaktivera manuell referensperiod och validera om med Arnold?", 
    "metering_points.revalidating": "Revaliderar...",   
    // create invalid data popup
    "metering_point.invalid_data_popup_title": "Markera valda punkter som giltiga/ogiltiga.",
    "metering_point.invalid_data_popup_valid": "Giltig",
    "metering_point.invalid_data_popup_invalid": "Ogiltig",

    "upload_deviations.upload_button_title": "Ladda upp protokoll",

    "chart.diagrams": "Diagram",
    "chart.change_end_date": "Ändra slutdatum",
    "chart.energy_diagram": "Energi",
    "chart.flow_diagram": "Volym",
    "chart.delta_T_diagram": "Temperaturer",
    "chart.from_date": "Visad period, fr.o.m.",
    "chart.to_date": "Visad period, t.o.m.",
    "chart.energy_per_day": "Energiförbrukning per dygn [MWh]",
    "chart.energy_daily_average": "Effektbehov dygnsmedel [kW]",
    "chart.flow_per_day": "Volym per dygn [m³]",
    "chart.flow_daily_average": "Volym per timme, dygnsmedel [m³]",
    "chart.temperature_outdoor_daily_average": "Utetemperatur dygnsmedel [°C]",
    "chart.group": "Grupp",
    "chart.group.measured": "uppmätta",
    "chart.group.calculated": "beräknade",
    "chart.group.invalid_data": " ogiltlig data",
    "chart.group.label": "etikett",
    "chart.temp": "Temp",
    "chart.marked": "Markerade",
    "chart.marked_period_length": "Markering, längd",
    "chart.marked_period_start_date": "Markering, fr.o.m.",
    "chart.marked_period_end_date": "Markering, t.o.m.",
    "chart.marked_period_step_forward": "Stega markering framåt",
    "chart.marked_period_step_back": "Stega markering bakåt",
    "chart.entire_period": "Hela perioden",
    "chart.no_marking": "Ingen markering",
    "chart.period_freeze": "Visad period låst",
    "chart.period_freeze_short": "Lås",
    "chart.edit_bad_data": "Lägg till/ta bort dåliga data",
    "chart.show_bad_data": "Visa dåliga data",
    "chart.show_bad_data_short": "Visa",
    "chart.toggle_chart_hover_label": "Visa tooltip i diagrammen när du håller muspekaren över en punkt",
    "chart.toggle_chart_hover_label_short": "Tooltip",
    "chart.toggle_energy_chart_unit": "Använd kW som enhet i energidiagrammen",
    "chart.toggle_energy_chart_unit_short": "kW",
    "chart.toggle_flow_chart_unit": "Använd m³/h som enhet i flödesdiagrammen",
    "chart.toggle_flow_chart_unit_short": "m³/h",
    "chart.label.energy": "Energi",
    "chart.label.power": "Gnms effekt",
    "chart.label.volume": "Volym",
    "chart.label.flow": "Gnms volym/h",
    "chart.month_names": "jan,feb,mar,apr,maj,jun,jul,aug,sep,okt,nov,dec",
    "chart.zoom": "Förstora",
    "chart.loading": "Laddar diagramdata...",
    "chart.flow_temperature": "Framtemp",
    "chart.return_temperature": "Returtemp",
    "chart.temperature_difference": "Flödesviktat Delta-T",
    "chart.outdoor_temperature": "Utetemp",
    "chart.temperature.y": "Temperatur [°C]",
    "chart.theme": "Diagramfärgtema",
    "chart.theme.default": "Standard",
    "chart.theme.classic": "Klassiskt",
    "chart.alarms": "Larmat",
    "chart.swap_date": "Byt mellan ref/visnings period",
    "chart.validate": "Skapa ny referens och validera om anläggning",
    "chart.validate_from_date": "Validera från",
    "chart.validate_to_date": "Validera till",
    "chart.validate_cancel": "Avbryt",
    "chart.validate_submit": "Validera",
    "chart.chart_linking": "Diagramlänkning",
    "chart.chart_linking.disabled": "Av",
    "chart.chart_linking.enabled": "På",
    "chart.widths": "Diagrambreddförhållande",
    "chart.height": "Diagramhöjd",
    "chart.separate_calculated": "Separera beräknade värden",
    "chart.separate_calculated_short": "Dela upp",
    "chart.symbol": "Diagramsymbol",
    "chart.symbol.circle": "Cirkel",
    "chart.symbol.square": "Kvadrat",
    "chart.symbol.diamond": "Romb",
    "chart.symbol.cross": "Kors",
    "chart.symbol.x": "Kryss",
    "chart.symbol.triangle": "Triangel",
    "chart.symbol.size": "Symbolstorlek",
    "chart.theme.tooltip.text": "Diagramfärgtema: Anger diagrammens färgtema.",
    "chart.chart_linking.tooltip.text":
      "Diagramlänkning: Anger ifall interaktion med ett diagram också syns i övriga diagram.",
    "chart.widths.tooltip.text":
      "Diagrambreddförhållande: Bestämmer hur signatur- och tidsdiagrammens bredd förhåller sig till varandra. 5:7 innebär t.ex. att signaturdiagrammet tar up 5/12 av bredden, och tidsdiagrammet 7/12.",
    "chart.height.tooltip.text": "Diagramhöjd: Bestämmer hur stora diagrammen är på höjden (i pixlar).",
    "chart.symbol.tooltip.text": "Diagramsymbol: Anger formen på punkterna i punktdiagrammen",
    "chart.symbol.size.tooltip.text": "Symbolstorlek: Anger hur stora punkterna i punktdiagrammen är",
    "chart.missing_data": "Saknas", 
    "chart.not_validated_data": "Ej validerad", 
    "chart.lastestValidated": "Senast validerad",
    "chart.not_all_data_validated": "All data är inte validerad",    
    "configuration.charts": "Diagram",
    "diffcalculation.referenceFrom": "Referensperiod, fr.o.m.", //Contains a no-break space
    "diffcalculation.referenceTo": "Referensperiod, t.o.m.", //Contains a no-break space
    "diffcalculation.appliedFrom": "Applicera fr.o.m.",
    "diffcalculation.appliedTo": "Applicera t.o.m.",
    "diffcalculation.calculate": "Beräkna",
    "diffcalculation.measured_consumption": "Uppmätt konsumtion",
    "diffcalculation.calculated_consumption": "Beräknad konsumtion",
    "diffcalculation.calculated_consumption_with_missing": "Beräknad konsumtion (varav saknade mätvärden)",
    "diffcalculation.difference": "Skillnad",
    "diffcalculation.result": "Resultat",
    "diffcalculation.error_message": "Fel vid efterberäkning",
    "diffcalculation.error_message_no_readings": "Inga mätvärden hittades i den specificerade referensperioden för ",
    "diffcalculation.missing_weather_data": "Väderdata saknas för följande dagar:",
    smart_energi_datalake_export: "Export Smart Energi gemensamt datalager",
    "smart_energi_datalake_export.enable": "Aktivera export",
    "smart_energi_datalake_export.disable": "Avaktivera export",
    "smart_energi_datalake_export.select_tag_value_placeholder": "Välj åtminstone ett värde..",
    "smart_energi_datalake_export.excluded_metering_point_fields": "Exkluderade mätpunktsfält",
    "smart_energi_datalake_export.select_excluded_metering_point_fields_placeholder":
      "Välj de mätpunktsfält som skall exkluderas vid export..",
    "smart_energi_datalake_export_preview.header": "Förhandsgranskning ",
    "smart_energi_datalake_export_preview.header.info": "Översikt",
    "smart_energi_datalake_export_preview.total": "Totalt: \t",
    "smart_energi_datalake_export_preview.exported": "Exporterad: \t",
    "smart_energi_datalake_export_preview.reduced": "Reducerad: \t",
    "smart_energi_datalake_export_preview.excluded": "Exkluderad: \t",
    "smart_energi_datalake_export_preview.metering_point_id": "Mätpunkt",
    "smart_energi_datalake_export_preview.header.per_tag": "Per tagg",
    "smart_energi_datalake_export_preview.preview": "Förhandsgranska",
    "metering_point.metering_point_id": "Mätpunkts-ID",
    "metering_point.address": "Adress",
    "metering_point.ref_period": "Referensperiod",
    "metering_point.tags": "Taggar",
    "metering_point.labels": "Etiketter",
    "metering_point.notes": "Anteckningar",
    "metering_point.deviations": "Avvikelse",
    "metering_point.filter_table_toggle_tooltip": "Filtrera taggarna baserat på AND/OR",
    "metering_point.filter_table_toggle_heading": "Filtrera taggarna",
    "MUIDataTables.textLabels": {
      body: {
        noMatch: "Ingen data att visa än",
        toolTip: "Sortera",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Nästa sida",
        previous: "Föregående sida",
        rowsPerPage: "Rader per sida",
        displayRows: "av",
      },
      toolbar: {
        search: "Sök",
        downloadCsv: "Ladda ned CSV",
        print: "Skriv ut",
        viewColumns: "Se kolumner",
        filterTable: "Filtrera tabell",
      },
      filter: {
        all: "Alla",
        title: "FILTER",
        reset: "ÅTERSTÄLL",
      },
      viewColumns: {
        title: "Visa kolumner",
        titleAria: "Visa/dölj tabellkolumner",
      },
      selectedRows: {
        text: "rad(er) markerade",
        delete: "Ta bort",
        deleteAria: "Ta bort markerade rader",
      },
    },
    // cooling
    "cooling.overflow": "Överflöde",
    "cooling.delta_t": "Flödesviktat Delta-T",
    "cooling.momentan_delta_t": "Momentansnitt Delta-T",
    "cooling.flow_temperature": "Framtemp",
    "cooling.return_temperature": "Returtemp",
    "cooling.calculated_at": "Senast beräknad",
    "cooling.sort": "Sortera",
    "cooling.loading": "Laddar...",
    "cooling.rows_per_page": "rader per sida",
    "cooling.of": "av",
    "cooling.filter_metering_point": "Filtrera på mätpunkter/adress",
    "cooling.download_csv": "Ladda ned CSV",

    // status
    "status.reading_1_day": "1 Dag",
    "status.reading_3_days": "3 Dagar",
    "status.reading_7_days": "7 Dagar",
    "status.error_list": "Mätvärden med felstatus",
    "status.error_list_missing_measures": "Mätpunkter som saknar mätvärden",
    "status.reading_status": "Status inläsning",
    "status.distribution": "Felstatus och saknade mätvärden",
    "status.errors_per_day": "Antal mätvärden med felstatus per dag",
    "status.loaded_readings": "mätvärden lästes in",
    "status.error_code": "Felkod",
    "status.timestamp": "Tidstamp",
    "status.day": "Dag",
    "status.address": "Adress",
    "status.metering_point_id": "Mätpunkts-ID",
    "status.description": "Beskrivning",
    "status.no_error": "Inga fel",
    "status.no_measures": "Mätvärden saknas",
    "status.total_number_of_metering_point_without_values": "Antalet mätpunkter som saknar mätvärden per dag",
    "status.no_address": "Adress saknas",
    "status.no_errors_reported": "Inga fel har rapporterats",
    "status.no_missing_meter_readings": "Inga mätvärden saknas",
    "status.loading": "Laddar",
    "status.failed_to_fetch_status": "Kan inte hämta status från server",
    //Error codes from meter_readings
    "status.validate_simple_error_negative_value": "Negativt värde på konsumtion importerat",
    "status.validate_simple_error_in_future": "Förbrukningsvärde från ett framtida datum",
    "status.validate_simple_error_false_positive": "Falskt positivt förbrukning",
    "status.calculate_consuming_values_error": "Mätvärde från föregående dag saknas",
    //Error codes from regression
    "status.validate_advanced_error_no_weather_data": "Ingen väder data hittades",
    "status.validate_advanced_error_no_metering_point_found": "Mätpunkten saknas",
    "status.validate_advanced_error_no_weather_station_info": "Ingen väder station hittades",
    "status.validate_advanced_error_unknown": "Okänt fel",
    "status.validate_advanced_error_no_reference_period": "Ingen referensperiod hittades",
    "status.validate_advanced_error_no_reference_readings": "Referensvärden saknas",
    "status.validate_advanced_error_too_few_reference_values": "För få referensvärden hittades",
    "status.not_validated": "Ej validerad"
  },
};

export default messages;
